<div class="container_dialog">
  <h1 mat-dialog-title>
    <div fxLayout="column">
      <!-- fxLayoutAlign="space-between center" -->
      <div 
        fxLayout="row"
        class="text-header-dialog"
      >
        <div fxFlex style="margin: 7px 25px 0 0;">
          {{header_text}}
        </div>
        
        <div class="close-button">
          <button
            mat-icon-button
            [mat-dialog-close]="false"
          >
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!-- <mat-divider class="divider"></mat-divider> -->
  </h1>

  <div class="text">
    {{text}}<b>{{nume}}</b>
  </div>      
  
   
  <div fxLayout="column">

    <br>

    <mat-dialog-actions fxLayout="row" style="margin-top: 10px;">
      <button 
        fxFlex 
        mat-button 
        mat-dialog-close
        class="buton-renunt"
      >
        <p>{{textButonStanga}}</p>
      </button>
  
      <!-- cdkFocusInitial -->
      <button
        fxFlex
        mat-stroked-button
        class="buton-continua"
        (click)="Continua()" 
      > 
        <p>{{textButonDreapta}}</p>
      </button>
    </mat-dialog-actions>
    <br>
  </div>

</div>