import { map, tap } from 'rxjs/operators';
import { AtentionareAcsService, BazaUserService, ApiEndpointService, IApiEndpointDef } from '@acs/infrastructura-standard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../backendapi/services';
import { UserService } from '../services/user.service';
import { MAT_SORT_HEADER_INTL_PROVIDER } from '@angular/material/sort';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ElementRef } from '@angular/core';


@Component({
  selector: 'app-autentificare',
  templateUrl: './autentificare.component.html',
  styleUrls: ['./autentificare.component.scss']
})
export class AutentificareComponent implements OnInit {

  @ViewChild('username') _usernameRef: ElementRef;
  form: FormGroup;
  hide = true;

  get username() {
    return this.form.get('username');
  }
  get password() {
    return this.form.get('password');
  }

  constructor(
    private fBuilder: FormBuilder,
    private authService: AuthService,
    private mesaj: AtentionareAcsService,
    private bazaUserService: BazaUserService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.form = this.fBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  autentificare(){
    if (this.form.valid) {
      this.authService.Login({
        username: this.username.value,
        password: this.password.value
      }).subscribe(raspuns => {
        console.log('raspuns', raspuns)
        if (!!raspuns.jwt) {
          this.bazaUserService.Token = raspuns.jwt;
          this.bazaUserService.NavigheazaLaHomepage();
        } else {
          // pot avea eroare de user
          if (raspuns.resetUser) {
            this.username.setValue('');
          } 
          // pot avea eroare de parola
          if (raspuns.resetPassword) {
            this.password.setValue('');
          }

          // afisez mesajul/mesajele de eroare primite de la backend
          let mesajEroare = '';
          if (!!raspuns.eroare) {
            mesajEroare = raspuns.eroare + '; \n';
          }
          mesajEroare += raspuns.eroare_user;

          this.eroareAutentificare();

          this.mesaj.Eroare(mesajEroare);
        }
      });
    }
  }

  eroareAutentificare() {
    this.setFocus();
    this.form.reset();
  }

  setFocus() {
    setTimeout(() => {
      this._usernameRef.nativeElement.focus()
    }, 50);
  }

}
