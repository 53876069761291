<div class="container_dialog">
  <h1 mat-dialog-title>
    <div fxLayout="column">
      <div 
        fxLayout="row"
        class="text-header-dialog"
      >
        <div fxFlex style="margin: 7px 25px 0 0;">
          <p>{{utilizatorId ? 'Reset parola' : 'Modific parola'}}</p>
        </div>

        <div class="close-button">
          <button
            mat-icon-button
            [mat-dialog-close]="false"
          >
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </h1>

  <form [formGroup]='userForm' fxLayout="column">

    <!-- User curent -->
    <div style="width: 245px !important;">
      <mat-form-field *ngIf="!utilizatorId"style="width: 245px !important;">
        <input
          #parolaVecheRef
          [type]="!tipDeVizualizare_parolaVeche ? 'password' : 'text'"
          matInput 
          placeholder="Parola Veche" 
          formControlName='parolaVeche'
          required
        >
        <mat-icon
          matSuffix
          class="iconita-gri-eye"
          (click)="!tipDeVizualizare_parolaVeche = !tipDeVizualizare_parolaVeche"
        >
          {{!tipDeVizualizare_parolaVeche ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- User curent + alt utilizator -->
    <mat-form-field>
      <input 
        #parolaNouaRef
        [type]="!tipDeVizualizare_parolaNoua ? 'password' : 'text'"
        matInput 
        placeholder="Parola Noua" 
        formControlName='parolaNoua'
        required
      >
        <mat-icon 
          matSuffix 
          class="iconita-gri-eye" 
          (click)="tipDeVizualizare_parolaNoua = !tipDeVizualizare_parolaNoua"
        >
          {{!tipDeVizualizare_parolaNoua ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      <mat-error>Campul este <strong>obligatoriu</strong></mat-error>
    </mat-form-field>

    <mat-form-field >
      <input 
        [type]="!tipDeVizualizare_confirmareParola ? 'password' : 'text'"
        matInput 
        placeholder="Confirmare Parola noua" 
        formControlName='confirmareParola' 
        [errorStateMatcher]='errorMatcher'
        required
      >
      <mat-icon 
        matSuffix 
        class="iconita-gri-eye" 
        (click)="tipDeVizualizare_confirmareParola = !tipDeVizualizare_confirmareParola"
      >
        {{!tipDeVizualizare_confirmareParola ? 'visibility_off' : 'visibility'}}
      </mat-icon>
      <mat-error *ngIf="userForm.hasError('passwordsDoNotMatch')">
        Parola Noua şi Confirmare Parola noua nu coincid!
      </mat-error>
    </mat-form-field>
    
  </form>
  
  <br>

  <mat-dialog-actions fxLayout="row" style="margin-top: 10px;">
    <button 
      fxFlex 
      mat-button 
      mat-dialog-close
      class="buton-renunt"
    >
      <p>Renunţă</p>
    </button>

    <button
      fxFlex
      mat-stroked-button
      class="buton-continua"
      (click)="SchimbaParola()"
      [disabled]="!userForm.valid"
    >
      <p>Schimbă</p>
    </button>
  </mat-dialog-actions>
  
  <br>

</div>