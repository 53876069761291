import { FrameComponent } from './frame/frame.component';
import { PanouriEditComponent } from './arhiva/components/panouri-edit/panouri-edit.component';
import { PanouriComponent } from './arhiva/components/panouri/panouri.component';
import { EditarePanouComponent } from './editare-panou/editare-panou.component';
import { ResolverUniversal } from './backendapi/resolver-universal';
import { ArhivaComponent } from './arhiva/arhiva.component';
import { AdministrareUtilizatoriComponent } from './administrare-utilizatori/administrare-utilizatori.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutentificareComponent } from './autentificare/autentificare.component';
import { ResolverUniversalArhiva } from './utils/resolver-universal-arhiva';

const routes: Routes = [
  {
    path:'autentificare',
    component: AutentificareComponent
  },
  {
    path:'administrare-utilizatori',
    component: AdministrareUtilizatoriComponent,
    resolve: {info: ResolverUniversal}
  },
  
  {
    path:'panouri',
    component: PanouriComponent,
    resolve: {info: ResolverUniversal}
  },
  {
    path:'panouri/:panouId',
    component: PanouriEditComponent,
    resolve: {info: ResolverUniversal}
  },

  {
    path:'editare-panou',
    component: EditarePanouComponent,
  },

  {
    path:'arhiva/:id1/:id2/:id3/:id4',
    component: ArhivaComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'arhiva/:id1/:id2/:id3',
    component: ArhivaComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'arhiva/:id1/:id2',
    component: ArhivaComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'arhiva/:id',
    component: ArhivaComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'arhiva',
    component: ArhivaComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'frame',
    component: FrameComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'frame/:id',
    component: FrameComponent,
    runGuardsAndResolvers: "always",
    resolve: {info: ResolverUniversalArhiva},
  },
  {
    path:'arhiva/**',
    redirectTo: '/arhiva',
    pathMatch: 'full'
  },

  {
    path: '**',
    redirectTo: '/arhiva',
    runGuardsAndResolvers: "always",
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
  enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
