import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-universal',
  templateUrl: './dialog-universal.component.html',
  styleUrls: ['./dialog-universal.component.scss']
})
export class DialogUniversalComponent implements OnInit {

  header_text: string;
  text: string;
  nume: string;
  textButonStanga: string;
  textButonDreapta: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<DialogUniversalComponent>
  ) {
    this.header_text = data.data.header_text;
    this.text = data.data.text;
    this.nume = data.data.nume;
    this.textButonStanga = data.data.textButonStanga ?? 'Renunţă';
    this.textButonDreapta = data.data.textButonDreapta ?? 'Continuă';
  }

  ngOnInit(): void {
  }

  Continua() {
    this.dialogRef.close(true);
  }

}
