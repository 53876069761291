<div fxLayout="column" fxLayoutAlign="center center" class="inaltime">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="container-autentificare">
      <mat-card>
        <mat-card-header>
          <h2>Autentificare</h2>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="form" fxLayout="column" fxLayoutGap="15px" (submit)="autentificare()">
            
            <div fxLayout="row" fxFlex="100">
              <mat-form-field style="flex: 1">
                <input
                  #username
                  matInput
                  type="text"
                  value=""
                  placeholder="Nume utilizator"
                  name="username"
                  id="username"
                  formControlName="username"
                  autofocus
                  required
                >
                <mat-error>Introduceţi o valoare</mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxFlex="100">
              <mat-form-field style="flex: 1" fxLayout="row" style="margin-bottom: 0px !important;">
                <input matInput [type]="hide ? 'password' : 'text'" value="" placeholder="Parola" name="password"
                  id="password" formControlName="password" autocomplete="off" required>
                <mat-error>Introduceţi o valoare</mat-error>
                <mat-icon matSuffix class="iconita_show_password" style="margin-left: 14px;" (click)="hide = !hide">
                  {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>

            <div fxLayout="row" class="butoane-action">
              <button 
                mat-stroked-button 
                fxFlex 
                type="submit"
                style="margin: 0px 0 12px 0;"
                [disabled]="form.status === 'INVALID'"
              >
                Autentificare
              </button>
            </div>  
          </form>

          <div fxLayout="row" style="margin-top: 5px;">
            <mat-divider style="width: 44%; margin-top: 7px;"></mat-divider>
            <span style="padding: 0 98px; font-size: 12px; color: rgb(167, 167, 167);">sau</span>
            <mat-divider style="margin-left: 138px; width: 44%; margin-top: 7px;"></mat-divider>
          </div>
    

          <div style="margin-top: 15px">
            <app-necesita-ms-login></app-necesita-ms-login>
          </div>

        </mat-card-content>
      </mat-card>
    </div>  
    
  </div>

</div>

