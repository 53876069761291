<app-cu-meniu>

  <div div class="container">

    <br>
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <!-- <div style="border: dashed 5px green;" fxFlex="50">
        <p>test</p>
        <div [innerHTML]="objectTest | safe: 'html'"></div>
      </div> -->

      <div fxFlex style="border: dashed 2px rgb(255, 136, 0); height: 160px;">
        Upload file test:
        <div class="row" style="margin-bottom:15px;">
          <div class="col-md-3">
            <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;">
            <button mat-stroked-button type="button" (click)="file.click()">Incarca Fisier</button>
          </div>

          <ng-container *ngIf="fisierSelectat$ | async">
            <pre>
              name: {{(fisierSelectat$ | async).name }}
              lastModifiedDate: {{(fisierSelectat$ | async).lastModifiedDate }}
              size: {{(fisierSelectat$ | async).size }}
              type: {{(fisierSelectat$ | async).type }}
            </pre>
          </ng-container>
          
          <div class="col-md-4">
            <span class="upload" *ngIf="progress > 0">
              {{progress}}%
            </span>
            <span class="upload" *ngIf="message">
              {{message}}
            </span>
          </div>
        </div>
      </div>

    </div>

    <br>
    <div fxLayout="row" fxLayoutGap="15px">
      <button mat-stroked-button (click)="aplicaSTIL()">
        Aplica STIL
      </button>
  
      <button mat-stroked-button (click)="eliminaSTIL()">
        Elimina STIL
      </button>
    </div>
    <br>
    
    <pre>{{object | json}}</pre>
    <br>


    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div style="border: dashed 5px red;">
        <b>STIL:</b> {{STIL}}<br>
        <b>Frame cu panouId:</b> {{panouId}}
        <mat-divider></mat-divider>
        
        <div [innerHTML]="object | safe: 'html'"></div>
      </div>
      
      <div style="border: dashed 5px lime;">
        https://test.adicomsoft.ro/hack_tabel_downloads.aspx
        <mat-divider></mat-divider>
        <div [innerHTML]="objectTest2 | safe: 'html'"></div>
      </div>

    </div>

  </div>

</app-cu-meniu>
<!-- sb.AppendLine("<p>TEST 123</p>"); -->