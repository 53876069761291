import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/*
  Notite despre configurare si administrare login via @adicomsoft:
  Serviciul functioneaza via Azure Active Directory, de-acolo se administreaza.
  Mergi la "Azure Portal" (https://portal.azure.com) si te loghezi cu contul @adicomsoft.ro - ar fi bine sa fii administrator ;-)
  Foloseste meniul "|||" si mergi la "Azure Active Directory". Vei gasi Tenant ID imediat, este scris la Basic Information.
  Mergi apoi la "App Registrations" - gasesti in meniul din stanga (meniul pentru pentru Active Directory)
  La "App Registrations" poti sa adaugi sau sa editezi aplicatii deja configurate. In dreptul fiecarei aplicatii vei gasi clientId (in UI se numeste Application (client) ID)
  Daca deschizi aplicatia pentru editare vei regasi "Application (client) ID" afisat in mod proeminent in interfata.
  In continuare te intereseaza "Redirect URIs" pentru aplicatia editata. 

  redirectUri: in aplicatia Angular configuram acest redirectUri, de regula in format relativ (exemplu: redirectUri: '/necesitamslogin').
               cand se face efectiv login acest URI este "extins" in forma lui absoluta; In exemplul furnizat, daca am accesat sit-ul
               via https://download.adicomsoft.ro atunci URI-ul extins/absolut devine: https://download.adicomsoft.ro/necesitamslogin
               Daca as fi accesat sit-ul via http://localhost:4200 atunci URL-ul extins/absolut devine: http://localhost:4200/necesitamslogin
               Ei bine acest URI extins trebuie configurat in Azure la "Redirect URIs"!

               Recapitulez: In redirectUri pui forma relativa (redirectUri: '/necesitamslogin')
                            In Azure configurezi URL-ul complet: https://download.adicomsoft.ro/necesitamslogin
*/

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '2a685246-bbe8-4365-a05e-42c4cde735a0', // <-- Application (client) ID din fisa pentru inregistrarea aplicatiei in portal.azure.com, vezi comentariul de mai sus
      redirectUri: '/necesitamslogin', // <-- vezi notita de mai sus cu privire la redirectUri
      postLogoutRedirectUri: '/',
      authority: 'https://login.microsoftonline.com/96ed199c-7c16-4fde-aca0-0c6ab5b1d71d/' // <-- Guid-ul vine din pagina "Tenant information" din Azure, vezi comentariul de mai sus
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MsalModule // Microsoft Authentication Library for JavaScript (MSAL.js)
  ],
  providers: [
    {provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory},
    {provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory},
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
})
export class LoginCuMicrosoftModule { }
