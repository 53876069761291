import { DialogCheieUploadComponent } from './../../arhiva/dialog/dialog-cheie-upload/dialog-cheie-upload.component';
import { DialogSchimbaResetParolaComponent } from './../../arhiva/dialog/dialog-schimba-reset-parola/dialog-schimba-reset-parola.component';
import { AuthService, UtilizatoriService } from 'src/app/backendapi/services';
import { AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt'
import { UtilsService } from 'src/app/boilerplate/utils-service';

@Component({
  selector: 'app-cu-meniu',
  templateUrl: './cu-meniu.component.html',
  styleUrls: ['./cu-meniu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CuMeniuComponent implements OnInit {

  color="secondary"

  constructor(
    public bazaUserService: BazaUserService,
    public userService: UserService,
    private authService: AuthService,
    public matDialog: MatDialog,
    private mesaj: AtentionareAcsService,
    private utilizatoriService: UtilizatoriService,
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
  }

  schimbatParola() {
    const dlgConfig: MatDialogConfig = {
      data: {
        UtilizatorId: null,
      }
    }
    
    this.matDialog.open(DialogSchimbaResetParolaComponent, {
      data: dlgConfig.data,
      autoFocus: false,
    }).afterClosed().subscribe((rezultat: any) => {
    })
  }

  cheieUpload() {
    this.utilizatoriService.CheieAutorizareUtilizatorLogat(this.utilsService.getIdUserLogat())
      .subscribe((cheie: string) => {
        const dlgConfig: MatDialogConfig = {
          data: {
            cheie: cheie.slice(1, -1),
          }
        }
        
        this.matDialog.open(DialogCheieUploadComponent, {
          data: dlgConfig.data,
          autoFocus: false,
          panelClass: 'clasa_identificare_cheie_upload',
        }).afterClosed().subscribe();
      })
  }

}
