import { DialogPanouSelectComponent } from './utils/dialogs/dialog-panou-select/dialog-panou-select.component';
import { ResolverUniversalArhiva } from './utils/resolver-universal-arhiva';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutentificareComponent } from './autentificare/autentificare.component';
import { AngularMaterialModule } from './boilerplate/angular-material.module';
import { AcsInfrastructuraModule } from '@acs/infrastructura-standard';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ApiModule } from './backendapi/api.module';
import { AdministrareUtilizatoriComponent } from './administrare-utilizatori/administrare-utilizatori.component';

import { NecesitaMsLoginComponent } from './necesita-ms-login/necesita-ms-login.component';
import { DialogAdaugaModificaUtilizatorComponent } from './administrare-utilizatori/dialog/dialog-adauga-modifica-utilizator/dialog-adauga-modifica-utilizator.component';
import { DialogUniversalComponent } from './utils/dialogs/dialog-universal/dialog-universal.component';
import { CommonModule, DatePipe } from '@angular/common';
import { LoginCuMicrosoftModule } from './login-cu-microsoft/login-cu-microsoft.module';
import { ArhivaComponent } from './arhiva/arhiva.component';
import { ArhivaFisierComponent } from './arhiva/components/arhiva-fisier/arhiva-fisier.component';
import { ArhivaFolderComponent } from './arhiva/components/arhiva-folder/arhiva-folder.component';
import { DialogModificaFolderComponent } from './arhiva/dialog/dialog-modifica-folder/dialog-modifica-folder.component';
import { DialogUtilizatoriPentruDialogModificaFolderComponent } from './arhiva/dialog/dialog-utilizatori-pentru-dialog-modifica-folder/dialog-utilizatori-pentru-dialog-modifica-folder.component';
import { EditarePanouComponent } from './editare-panou/editare-panou.component';
import { ButonAutentificareComponent } from './buton-autentificare/buton-autentificare.component';
import { FolderEditarePanouComponent } from './arhiva/components/folder-editare-panou/folder-editare-panou.component';
import { FisierEditarePanouComponent } from './arhiva/components/fisier-editare-panou/fisier-editare-panou.component';
import { PanouriComponent } from './arhiva/components/panouri/panouri.component';
import { PanouriEditComponent } from './arhiva/components/panouri-edit/panouri-edit.component';
import { CuMeniuComponent } from './template/cu-meniu/cu-meniu.component';
import { PanouElementComponent } from './arhiva/components/panouri-edit/panou-element/panou-element/panou-element.component';
import { DialogSchimbaResetParolaComponent } from './arhiva/dialog/dialog-schimba-reset-parola/dialog-schimba-reset-parola.component';
import { FrameComponent } from './frame/frame.component';
import { SafePipe } from './frame/safe.pipe';
import { DialogCheieUploadComponent } from './arhiva/dialog/dialog-cheie-upload/dialog-cheie-upload.component';

@NgModule({
  declarations: [
    AppComponent,
    AutentificareComponent,
    AdministrareUtilizatoriComponent,
    NecesitaMsLoginComponent,
    DialogAdaugaModificaUtilizatorComponent,
    DialogUniversalComponent,
    ArhivaComponent,
    ArhivaFolderComponent,
    ArhivaFisierComponent,
    DialogModificaFolderComponent,
    DialogUtilizatoriPentruDialogModificaFolderComponent,
    EditarePanouComponent,
    ButonAutentificareComponent,
    DialogPanouSelectComponent,
    FolderEditarePanouComponent,
    FisierEditarePanouComponent,
    PanouriComponent,
    PanouriEditComponent,
    CuMeniuComponent,
    PanouElementComponent,
    DialogSchimbaResetParolaComponent,
    FrameComponent,
    SafePipe,
    DialogCheieUploadComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule,
    ApiModule,
    AcsInfrastructuraModule.forRoot(environment),
    LoginCuMicrosoftModule
  ],
  providers: [
    ResolverUniversalArhiva,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ], 
})
export class AppModule { }
