/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, catchError as __catchError, finalize as __finalize } from 'rxjs/operators';
import { ApiEndpointService, AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { DetaliiPanou } from '../models/detalii-panou';
import { SolicitarePanou } from '../models/solicitare-panou';
import { SolicitareListaPanouri } from '../models/solicitare-lista-panouri';
import { SumarPanou } from '../models/sumar-panou';
@Injectable({
  providedIn: 'root',
})
class CfgPanouService extends __BaseService {
  static readonly AdaugaSauModificaPanouPath = '/api/CfgPanou/AdaugaSauModificaPanou';
  static readonly DetaliiPanouPath = '/api/CfgPanou/DetaliiPanou';
  static readonly FramePath = '/api/CfgPanou/Frame/{panouId}';
  static readonly ListaPanouriDefinitePath = '/api/CfgPanou/ListaPanouriDefinite';
  static readonly StergePanouPath = '/api/CfgPanou/StergePanou';

  constructor(
    config: __Configuration,
    http: HttpClient,
    user: BazaUserService,
    apiEndpoint: ApiEndpointService,
	at: AtentionareAcsService,
  private router: Router
  ) {
    super(config, http, user, apiEndpoint, at);
  }

  /**
   * @param AdaugaSauModificaPanouBodyParam
   */
  AdaugaSauModificaPanouResponse(AdaugaSauModificaPanouBodyParam: DetaliiPanou, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdaugaSauModificaPanouBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CfgPanou/AdaugaSauModificaPanou`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'AdaugaSauModificaPanou' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param AdaugaSauModificaPanouBodyParam
   */
  AdaugaSauModificaPanou(AdaugaSauModificaPanouBodyParam: DetaliiPanou, faraProgressBar?: boolean): __Observable<null> {
    return this.AdaugaSauModificaPanouResponse(AdaugaSauModificaPanouBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param DetaliiPanouBodyParam
   * @return Success
   */
  DetaliiPanouResponse(DetaliiPanouBodyParam: SolicitarePanou, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<DetaliiPanou>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = DetaliiPanouBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CfgPanou/DetaliiPanou`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'DetaliiPanou' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DetaliiPanou>;
      })
    );
  }

  /**
   * @param DetaliiPanouBodyParam
   * @return Success
   */
  DetaliiPanou(DetaliiPanouBodyParam: SolicitarePanou, faraProgressBar?: boolean): __Observable<DetaliiPanou> {
    return this.DetaliiPanouResponse(DetaliiPanouBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as DetaliiPanou)
    );
  }

  /**
   * @param params The `CfgPanouService.FrameParams` containing the following parameters:
   *
   * - `panouId`:
   *
   * - `latimeFrame`:
   *
   * - `stil`:
   *
   */
  FrameResponse(params: CfgPanouService.FrameParams, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.latimeFrame != null) __params = __params.set('latimeFrame', params.latimeFrame.toString());
    if (params.stil != null) __params = __params.set('stil', params.stil.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CfgPanou/Frame/${params.panouId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'Frame' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CfgPanouService.FrameParams` containing the following parameters:
   *
   * - `panouId`:
   *
   * - `latimeFrame`:
   *
   * - `stil`:
   *
   */
  Frame(params: CfgPanouService.FrameParams, faraProgressBar?: boolean): __Observable<null> {
    return this.FrameResponse(params, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ListaPanouriDefiniteBodyParam
   * @return Success
   */
  ListaPanouriDefiniteResponse(ListaPanouriDefiniteBodyParam: SolicitareListaPanouri, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<Array<SumarPanou>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ListaPanouriDefiniteBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CfgPanou/ListaPanouriDefinite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'ListaPanouriDefinite' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SumarPanou>>;
      })
    );
  }

  /**
   * @param ListaPanouriDefiniteBodyParam
   * @return Success
   */
  ListaPanouriDefinite(ListaPanouriDefiniteBodyParam: SolicitareListaPanouri, faraProgressBar?: boolean): __Observable<Array<SumarPanou>> {
    return this.ListaPanouriDefiniteResponse(ListaPanouriDefiniteBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as Array<SumarPanou>)
    );
  }

  /**
   * @param panouId
   */
  StergePanouResponse(panouId?: string, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (panouId != null) __params = __params.set('panouId', panouId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CfgPanou/StergePanou`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'StergePanou' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param panouId
   */
  StergePanou(panouId?: string, faraProgressBar?: boolean): __Observable<null> {
    return this.StergePanouResponse(panouId, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

}

module CfgPanouService {

  /**
   * Parameters for Frame
   */
  export interface FrameParams {
    panouId: string;
    latimeFrame?: number;
    stil?: string;
  }
}

export { CfgPanouService }