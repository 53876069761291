<div fxLayout="column" fxFlex style="margin: 13px 0">
  <div 
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <img
        [src]="fisier.ico[0]"
        alt="imagine-fisier"
        style="z-index: 1; margin-right: 5px;"
        fxFlex="60px"
      >
      
      <div fxLayout="column" fxFlex.gt-xs="180px">
        <p style="font-weight: 400;" class="">{{fisier.numeFisier}}</p>
        <p style="font-size: 12px !important">{{fisier.versiune}}</p>
        <p style="font-size: 12px !important">{{fisier.data}}</p>
        <p fxHide.gt-xs style="font-size: 12px !important">{{fisier.dimensiune}}</p>
       </div>
    </div>
  
    <div fxFlex>
      <p class="comentariu">{{fisier.comentariu}}</p>
    </div>

    <ng-container *ngIf="fisier.beta">
      <mat-chip-list aria-label="Fish selection">
        <mat-chip color="secondary" selected>Beta</mat-chip>
      </mat-chip-list>
    </ng-container>
    
    <p 
      fxFlex.gt-xs="100px" 
      fxFlexOffset="18px"
      fxHide.lt-xs 
      fxHide.xs 
    >{{fisier.dimensiune}}</p>

    <ng-container *ngIf="tipDeSelect === 'fisier'">
      <button
        mat-stroked-button
        class="buton_alege"
        (click)="$event.stopPropagation(); alegeFisier.emit(fisier)"
      >
        Alege
      </button>
    </ng-container>
  </div>
  <p fxFlex class="comentariu-telefon">{{fisier.comentariu}}</p>
  
</div>