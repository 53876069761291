<div 
  [ngStyle]="{'cursor': (modVizualizarePanou) ? 'all-scroll' : 'default'}"
>
  <div fxLayout="column" fxFlex style="margin: 13px 0">
    <div 
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >

      <a [href]="fisier.downloadUrl" [download]="fisier.numeFisier" style="cursor: pointer !important; color: black;">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <!-- [src]="imagine" -->
          <img
            [src]="!!fisier.ico[0] ? fisier.ico[0] : imagine"
            alt="imagine-fisier"
            class="modifica_ico"
            fxFlex="60px"
          >
          <div fxLayout="column" fxFlex.gt-xs="180px">
            <p style="font-weight: 400;" class="">{{fisier.numeFisier}}</p>
            <p style="font-size: 12px !important">{{fisier.versiune}}</p>
            <p style="font-size: 12px !important">{{fisier.data}}</p>
            <p fxHide.gt-xs style="font-size: 12px !important">{{fisier.dimensiune}}</p>
          </div>
        </div>
      </a>
    
      <div fxFlex>
        <p class="comentariu">
          {{fisier.comentariu}}
        </p>
      </div>
  
      <!-- butoane Beta/Defect/Release -->
      <div>
        <ng-container *ngIf="fisier.beta">
          <button 
            mat-button 
            (click)="eliminaBeta()"
            class="buton-beta"
            [ngStyle]="{
              'background-color': fisier.badBeta ? '#df4f35' : '#ff8b77',
              'color': 'white'
            }"
          >
            {{fisier.badBeta ? 'Defect' : 'Beta'}}
          </button>
        </ng-container>
  
        <ng-container *ngIf="!fisier.beta && okPunLaLocBIfaBeta">
          <button 
            mat-button 
            (click)="butonRelease()"
            class="buton-beta"
          >
            Release
          </button>
        </ng-container>
      </div>

      <p 
        fxFlex.gt-xs="100px"
        fxFlexOffset="18px"  
        fxHide.lt-xs
        fxHide.xs
      >{{fisier.dimensiune}}</p>
              
      <div fxLayoutAlign="end center">
        <a [href]="fisier.downloadUrl" [download]="fisier.numeFisier">
          <button 
            mat-icon-button 
            fxLayoutAlign="center center"
          >
            <mat-icon class="icon-download">download</mat-icon>
          </button>
        </a>
      </div>
  
      <div fxLayoutAlign="end center">
        <button
          mat-icon-button
          fxLayoutAlign="center center"
          [cdkCopyToClipboard]="fisier.downloadUrl"
          (click)="fisier.downloadUrl; openSnackBar('Link-ul pentru a descarca fisierul a fost copiat in clipboard', ''); $event.stopPropagation();"
          [matTooltip]="'Copiaza link in clipboard'"
        >
          <mat-icon class="icon-download">link</mat-icon>
        </button>
      </div>
      
    </div>
    <p fxFlex class="comentariu-telefon">
      {{fisier.comentariu}}
    </p>
  </div>
</div>
