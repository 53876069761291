<h1 mat-dialog-title style="margin-bottom: -7px !important">
  <div fxLayout="column">
    <!-- fxLayoutAlign="space-between center" -->
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxFlex style="margin: 7px 25px 0 0;">
        <p>Dtalii Utilizatori</p>
      </div>
      
      <div class="close-button">
        <button
          mat-icon-button
          [mat-dialog-close]="false"
        >
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</h1>

<br>

<mat-dialog-content>
  <mat-selection-list #utilizator color="primary" [multiple]="false">
    <mat-list-option *ngFor="let e of detaliiUtilizator" [value]="e">
      <p>{{e.nume}}</p>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<br>

<div fxLayout="column">
  <mat-dialog-actions fxLayout="row" style="margin-top: 10px;">
    <button 
      fxFlex 
      mat-button 
      class="buton-renunt"
      mat-dialog-close
    ><p>Renunţă</p></button>
      
    <button
      fxFlex
      mat-stroked-button
      class="buton-continua"
      (click)="continua(utilizator.selectedOptions.selected[0]?.value)"
      [disabled]="!utilizator.selectedOptions.selected[0]?.value"
    >
      <p>Continuă</p>
    </button>
  </mat-dialog-actions>
  <br>
</div>
