<div style="background-color: #e9f6ff;" class="mat-elevation-z1">
  <div class="container">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" style="background-color: transparent;">
      <div>
        <a class="logo" [routerLink]="['/arhiva']">DOW</a>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="17px">
        <a class="label" routerLinkActive="active" #rla="routerLinkActive" [routerLink]="['/arhiva']">Arhiva</a>

        <ng-container *ngIf="bazaUserService.ExistaJwtValid$ | async; else nuEsteAutentificat">

          <!-- Doar utilizatorul cu rol de 'Admin' -->
          <a class="label" routerLinkActive="active" [routerLink]="['/panouri']">Panouri</a>
          <ng-container *ngIf="bazaUserService.listaRoluri.includes('Admin')">
            <a class="label" routerLinkActive="active" [routerLink]="['/administrare-utilizatori']">Utilizatori</a>
          </ng-container>
          
          <button 
            class="menu-button" 
            mat-icon-button 
            [mat-menu-trigger-for]="menu"           
            [color]="color"
            (mouseover)="color = 'primary'"
            (mouseout)="color = 'secondary'"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>
        <ng-template #nuEsteAutentificat>
          <div fxLayout="row" fxFlexOffset="10">
            <app-buton-autentificare></app-buton-autentificare>
          </div>
        </ng-template>
        
      </div>
    
    </mat-toolbar>
  </div>
</div>

<ng-content></ng-content>

<mat-menu x-position="before" #menu="matMenu">

  <button mat-menu-item (click)="schimbatParola()">
    <p>
      <mat-icon>vpn_key</mat-icon>
      Schimba parola
    </p>
  </button>

  <button mat-menu-item (click)="cheieUpload()">
    <p>
      <mat-icon>password</mat-icon>
      Cheie upload
    </p>
  </button>

  <mat-devider></mat-devider>
  <hr style="margin: 0;"> 

  <button mat-menu-item (click)="userService.logout();" class="logout">
    <p class="logout">
      <mat-icon class="logout">exit_to_app</mat-icon>
      Deconectare (log-out)
    </p>
  </button>

  
</mat-menu>