import { DialogAdaugaModificaUtilizatorComponent } from './dialog/dialog-adauga-modifica-utilizator/dialog-adauga-modifica-utilizator.component';
import { UserService } from './../services/user.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Apis } from '../backendapi/resolver-universal';
import { DetaliiUtilizator, ItemSelectie } from '../backendapi/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UtilizatoriService } from '../backendapi/services';
import { DialogUniversalComponent } from '../utils/dialogs/dialog-universal/dialog-universal.component';
import { DialogSchimbaResetParolaComponent } from '../arhiva/dialog/dialog-schimba-reset-parola/dialog-schimba-reset-parola.component';
import { AtentionareAcsService } from '@acs/infrastructura-standard';

@Component({
  selector: 'app-administrare-utilizatori',
  templateUrl: './administrare-utilizatori.component.html',
  styleUrls: ['./administrare-utilizatori.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdministrareUtilizatoriComponent implements OnInit {

  // nume email telefon flaguriSecuritate flaguriSecuritatePosibiel
  public Detalii: DetaliiUtilizator[] = [];
  public arrayPentruFiltru: DetaliiUtilizator[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'nume', 'email', 'telefon', 'flagSecUtilizator', 'flagSecAdmin', 'actiuni'];
  dataSource: MatTableDataSource<DetaliiUtilizator>;

  mouseHover: number;
  url: string = this.router.url;
  
  @ViewChild('adaugaDenumire', {static: false}) _adaugaDenumireRef: ElementRef;

  adauga() {
    setTimeout(() => {
      this._adaugaDenumireRef.nativeElement.focus();
    });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private utilizatoriService: UtilizatoriService,
    private mesaj: AtentionareAcsService,
  ) { }

  public static ResolveUniversal(param: Params, queryParams: Params, apis: Apis, userService: UserService): Observable<any> {
    return apis.utilizatori.ListaUtilizatori();
  }

  ngOnInit(): void {
    // populam state-ul
    this.route.data.subscribe((rezultat: any) => {
      this.Detalii = rezultat.info;
      console.log('### rezultat.info', rezultat.info);
      this.filtru();
    });
  }

  filtru() {
    // filtre
    this.arrayPentruFiltru = this.Detalii;

    this.dataSource = new MatTableDataSource<DetaliiUtilizator>(this.arrayPentruFiltru);
    this.dataSource.paginator = this.paginator;
  }

  dialogAdaugaModificaUtilizator(detaliiUtilizator?: DetaliiUtilizator) {
    const dlgConfig = new MatDialogConfig();
    if (detaliiUtilizator) {
      console.log('modifica', );
      dlgConfig.data = {
        detaliiUtilizator
      };
    } else {
      console.log('adauga');
      let detaliiUtilizator: DetaliiUtilizator = {
        utilizatorId: undefined,
        nume: undefined,
        email: undefined,
        telefon: undefined,
        flaguriSecuritate: undefined,
        flaguriSecuritatePosibile: undefined
      }
      dlgConfig.data = {
        detaliiUtilizator
      };
    }

    this.matDialog.open(DialogAdaugaModificaUtilizatorComponent, {
      data: dlgConfig.data,
      autoFocus: false,
      panelClass: 'dialog-class-detalii-parinte',
    }).afterClosed().subscribe((rezultat: DetaliiUtilizator) => {
      if (!!rezultat) {
        this.fetchListaUtilizatori();
      }
    })
  }

  getFlag(detaliiUtilizator: ItemSelectie[], keyText: string): boolean {
    for (const e of detaliiUtilizator) {
      if (e.text === keyText) {
        return !!e.valoare;
      }
    }
    return false;
  }

  stergeUtilizator(detaliiUtilizator : DetaliiUtilizator) {
    const dlgConfig = new MatDialogConfig();
    dlgConfig.data = {
      header_text: "Confirmare stergere Utilizator",
      text: "Sigur doriti sa stergeti utilizatorul cu numele ",
      nume: detaliiUtilizator.nume + "?"
    };

    this.matDialog.open(DialogUniversalComponent, {
      data: dlgConfig,
      autoFocus: false,
      panelClass: 'dialog-class-detalii-parinte',
    }).afterClosed().subscribe(rezultat => {
      if (!!rezultat) {
        this.utilizatoriService.StergeUtilizator(detaliiUtilizator.utilizatorId).subscribe(() => {
          this.fetchListaUtilizatori();
        })
      }
    })
  }

  fetchListaUtilizatori() {
    this.utilizatoriService.ListaUtilizatori().subscribe(rezultat => {
      console.log('rezultat', rezultat);
      this.Detalii = rezultat;
      this.filtru();
    })
  }

  resetareParola(detaliiUtilizator: DetaliiUtilizator) {
    const dlgConfig: MatDialogConfig = {
      data: {
        UtilizatorId: detaliiUtilizator.utilizatorId,
        NumeUtilizator: detaliiUtilizator.nume
      }
    }
    
    this.matDialog.open(DialogSchimbaResetParolaComponent, {
      data: dlgConfig.data,
      autoFocus: false,
    }).afterClosed().subscribe((rezultat: any) => {
    })
  }
  
}
