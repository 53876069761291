<app-cu-meniu>

  <br>
  
  <div class="container" #scrollMe>

    <!-- breadCrumb -->
    <div fxLayout="row" fxLayoutAlign="start center" style="margin: 5px 0 0 0;">
      <ng-container *ngIf="segmentUrl.length > 0">
        <a 
          fxLayout="row"
          class="breadCrumb"
          [routerLink]="['/arhiva']"
        >
          <p>Arhiva</p>
        </a>
      </ng-container> 
  
      <ng-container *ngIf="!!segmentUrl && segmentUrl.length >= 1">
        <p class="dash">&nbsp;/&nbsp;</p>
        <a 
          fxLayout="row"
          class="breadCrumb"
          [routerLink]="['/arhiva', segmentUrl[0]]"
        >
          <p>{{segmentUrl[0]}}</p>
        </a>
      </ng-container>
      
      <ng-container *ngIf="!!segmentUrl && segmentUrl.length >= 2">
        <p class="dash">&nbsp;/&nbsp;</p>
        <a fxLayout="row"
          class="breadCrumb"
          [routerLink]="['/arhiva', segmentUrl[0], segmentUrl[1]]"
        >
          {{segmentUrl[1]}}
        </a>
      </ng-container>
  
      <ng-container *ngIf="!!segmentUrl && segmentUrl.length >= 3">
        <p class="dash">&nbsp;/&nbsp;</p>
        <a 
          fxLayout="row"
          class="breadCrumb"
          [routerLink]="['/arhiva', segmentUrl[0], segmentUrl[1], segmentUrl[2]]"
        >
          {{segmentUrl[2]}}
        </a>
      </ng-container>
    </div>

    
    <ng-container *ngIf="!!viewArhiva">
  
      <div 
        fxLayout="row" 
        fxLayoutAlign="space-between center"
        fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="center center"
        fxLayoutGap="10px"
      >
        <ng-container *ngIf="!!viewArhiva.descriere || !!viewArhiva.ico[0]">
          <ng-container *ngIf="!!viewArhiva.descriere"> 
            <div fxLayout="column">
              <p style="font-weight: 400; margin-left: 9;">
                {{viewArhiva.descriere}} 
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="!!viewArhiva.ico[0]">
            <div fxLayout="row">
              <img 
                [src]=viewArhiva.ico[0]
                alt="imagine-viewArhiva"
                class="modifica_ico"
              >
              <ng-container *ngIf="bazaUserService.ExistaJwtValid$ | async">
                <ng-container *ngIf="viewArhiva.okEditor">
                  <div fxLayout="column" fxLayoutGap="10px">
                    <button mat-icon-button (click)="dialogModificaFolderRoot()">
                      <mat-icon class="buton-edit">edit</mat-icon>
                    </button>
                    <div 
                      fxLayout="row"
                      fxLayoutGap="5px"
                      [ngStyle]="{
                        'padding': '2px 4px 2px 0px',
                        'border': modEditare ? '1px solid #aaaaaa' : '1px solid transparent',
                        'border-radius': '25px'
                      }"
                    >
                      <ng-container *ngIf="modEditare">
                        <button mat-icon-button (click)="editModeOff()">
                          <mat-icon class="buton-edit">close</mat-icon>
                        </button>
                      </ng-container>
                      <button mat-icon-button (click)="modEditare ? saveEditMode() : editMode()">
                        <mat-icon class="buton-edit">{{modEditare ? 'save' : 'flip_to_back'}}</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="bazaUserService.ExistaJwtValid$ | async"> 
        <input 
          #csvInput 
          hidden="true" 
          type="file" 
          onclick="this.value=null" (change)="incarcaFisier($event)"
        />
        <button
          mat-raised-button 
          (click)="csvInput.click()" 
          class="buton-imagine" 
          type="button"
          [disabled]="procesDeIncarcare"
    
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p>{{!procesDeIncarcare? 'Încarcă fisier' : 'Se incarca...'}}</p>
            <ng-container *ngIf="procesDeIncarcare">
              <div style="color: red">
                <mat-spinner [diameter]="20" ></mat-spinner>
              </div>
            </ng-container>
          </div>
        </button>
      </ng-container>


      <div *ngFor="let folder of viewArhiva.folder">
        <p>{{folder.denumire}}</p><br>
      </div>
  
      <!-- modEditare True -->
      <ng-container *ngIf="modEditare; else modEditareFlase">
        <div cdkDropList class="list-modEditat" (cdkDropListDropped)="drop($event)">
          <div class="box-modEditat" *ngFor="let folder of foldere" cdkDrag>
            <div class="custom-placeholder" *cdkDragPlaceholder ></div>
            <app-arhiva-folder [folder]="folder"></app-arhiva-folder>
          </div>
        </div>
      </ng-container>
  
      <!-- modEditare False -->
      <ng-template #modEditareFlase>
        <ng-container *ngIf="!!viewArhiva.foldere">
          <ng-container *ngFor="let folder of viewArhiva.foldere">
            <app-arhiva-folder 
              [folder]="folder"
              [imagine]="viewArhiva.ico[1]"
              [okPunLaLocBIfaBeta]="viewArhiva.okPunLaLocBIfaBeta"
            ></app-arhiva-folder>
          </ng-container>
        </ng-container>
      </ng-template>
      
      <ng-container *ngIf="viewArhiva?.fisiere">
        <ng-container *ngFor="let fisier of viewArhiva.fisiere">
          <app-arhiva-fisier 
            [fisier]="fisier" 
            [imagine]="viewArhiva.ico[1]"
            [segmentUrl]="segmentUrl"
            [okPunLaLocBIfaBeta]="viewArhiva.okPunLaLocBIfaBeta"
            [okEliminBifaBeta]="viewArhiva.okEliminBifaBeta"
          ></app-arhiva-fisier>
        </ng-container>
      </ng-container>
  
    </ng-container>
    
    <br>
    <br>
  
  </div>

</app-cu-meniu>

