import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DetaliiUtilizator, ItemSelectie } from 'src/app/backendapi/models';
import { UtilizatoriService } from 'src/app/backendapi/services';
import { EmptyGUID } from 'src/app/boilerplate/constante.comune';

@Component({
  selector: 'app-dialog-adauga-modifica-utilizator',
  templateUrl: './dialog-adauga-modifica-utilizator.component.html',
  styleUrls: ['./dialog-adauga-modifica-utilizator.component.scss']
})
export class DialogAdaugaModificaUtilizatorComponent implements OnInit, AfterViewInit {

  detaliiUtilizatorForm: FormGroup;
  // acest camp poate sa fie true sau false, in functie de rezultatul API
  // acel api este inexistent momentan
  utilizatorulPoateIncarcaKitDeInstalareAutomat: boolean;

  @ViewChild('numeForm') _numeFormRef: ElementRef;
  @ViewChild('numeleUtilizatoruluiInArhiva') _numeleUtilizatoruluiInArhivaRef: ElementRef;

  utilizator: number = 0;
  admin: number = 0;

  EmptyGUID = EmptyGUID;
  utilizatorNou = false;

  flaguriSecuritate: number[] = [];
  flaguriSecuritatePosibile: ItemSelectie[] = [];

  // Pentru cazul in care se adauga un nou utilizator nu avem aceste informatii
  localFlaguriSecuritatePosibile: ItemSelectie[] = [
    {text: "Admin", valoare: 0},
    {text: "Utilizator", valoare: 1}
  ]

  afisareInputIncarcareKit = false;
  requestCheie = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<DialogAdaugaModificaUtilizatorComponent>,
    private formBuilder: FormBuilder,
    private utilizatoriService: UtilizatoriService,
    private snackBar: MatSnackBar,
  ) {

    if (!!data.detaliiUtilizator.flaguriSecuritatePosibile) {
      this.flaguriSecuritatePosibile = [...data.detaliiUtilizator.flaguriSecuritatePosibile];
      this.flaguriSecuritate = [...data.detaliiUtilizator.flaguriSecuritate];
    } else {
      this.flaguriSecuritatePosibile = this.localFlaguriSecuritatePosibile;
    }
    this.initForm(data.detaliiUtilizator);
  }

  initForm(detaliiUtilizator: DetaliiUtilizator) {
    this.detaliiUtilizatorForm = this.formBuilder.group({
      utilizatorId: [detaliiUtilizator.utilizatorId ? detaliiUtilizator.utilizatorId : this.EmptyGUID],
      nume: [detaliiUtilizator.nume, [Validators.nullValidator]],
      email: [detaliiUtilizator.email, [Validators.nullValidator]],
      telefon: [detaliiUtilizator.telefon],
      cheieDeAutorizare: {value: null, disabled: true},
      numeleUtilizatoruluiInArhiva : {value: '', disabled: true},
    });
    this.utilizatorulPoateIncarcaKitDeInstalareAutomat = !!this.cheieDeAutorizare.value ? true : false;
  }
  
  get utilizatorId() {
    return this.detaliiUtilizatorForm.get('utilizatorId');
  }
  get nume() {
    return this.detaliiUtilizatorForm.get('nume');
  }
  get email() {
    return this.detaliiUtilizatorForm.get('email');
  }
  get telefon() {
    return this.detaliiUtilizatorForm.get('telefon');
  }
  get cheieDeAutorizare() {
    return this.detaliiUtilizatorForm.get('cheieDeAutorizare');
  }
  get numeleUtilizatoruluiInArhiva() {
    return this.detaliiUtilizatorForm.get('numeleUtilizatoruluiInArhiva');
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._numeFormRef.nativeElement.focus();
    }, 1);
  }

  checkboxChange(valoare: number, e: boolean) {
    if (e) {
      this.flaguriSecuritate.push(valoare);
    } else {
      let poz = this.flaguriSecuritate.findIndex(e => e === valoare);
      this.flaguriSecuritate.splice(poz, 1);
    }
  }

  convert(): Array<0 | 1> {
    let listPentruReturn:  Array<0 | 1> = [];

    for (const e of this.flaguriSecuritate) {
      if (e === 0) {
        listPentruReturn.push(0);
      } 
      else if (e === 1) {
        listPentruReturn.push(1);
      }
    }
    return listPentruReturn;
  }

  solicitCheieDacaNuAm() {

    if (!this.cheieDeAutorizare.value) {

      if (this.utilizatorId.value === EmptyGUID) {

        this.cheieDeAutorizare.setValue(EmptyGUID);

        this.requestCheie = true;

        this.utilizatorulPoateIncarcaKitDeInstalareAutomat = true;
        // enabled input numele utilizatorului
        this.numeleUtilizatoruluiInArhiva.enable();
        // focus
        this.selectInputUtilizatorulPoateIncarcaKit();
        // iconita loading
        this.requestCheie = false;
      }
  
      // #1 daca nu are cheie
      // #2 nu va mai intra pe aici daca a facut odata requestul la api si a populat field-ul cheieDeAutorizare
      // asta pentru ca, cheile sunt unice si nu se vor mai schimba
      if (this.utilizatorId.value !== EmptyGUID) {
        this.requestCheie = true;
  
        // simulam req. la API cu un response de 3 secunde
        this.utilizatoriService.GenereazaCheieAutorizareUpload(this.utilizatorId.value)
          .subscribe((cheie: string) => {
            // pe subscribe vom seta local cheia primita
            // this.cheieDeAutorizare.setValue('TIK494LFujCM1230CAScsaasd$(FACm1234m -local');
            this.cheieDeAutorizare.setValue(cheie.slice(1, -1));
            this.utilizatorulPoateIncarcaKitDeInstalareAutomat = true;
    
            // enabled input numele utilizatorului
            this.numeleUtilizatoruluiInArhiva.enable();
            // focus
            this.selectInputUtilizatorulPoateIncarcaKit();
            // iconita loading
            this.requestCheie = false;
          })
  
      } 
    }
    else {
      // daca am ajuns aici avem o cheie
      this.utilizatorulPoateIncarcaKitDeInstalareAutomat = !this.utilizatorulPoateIncarcaKitDeInstalareAutomat;
      
      if (this.utilizatorulPoateIncarcaKitDeInstalareAutomat) {
        this.selectInputUtilizatorulPoateIncarcaKit();
      }
      if (!this.utilizatorulPoateIncarcaKitDeInstalareAutomat) {
        // pentru a nu face alte requesturi inutile, nu se sterge cheia
        // daca checkboxul este false, la apasarea butonului de salvare se vor popula property-urile
        // utilizatorulPoateIncarcaKitDeInstalareAutomat respectiv cheieDeAutorizare cu valoarea null.
        this.numeleUtilizatoruluiInArhiva.setValue('');
      }
    }

  }

  selectInputUtilizatorulPoateIncarcaKit() {
    setTimeout(() => {
      this._numeleUtilizatoruluiInArhivaRef.nativeElement.focus();
    }, 1);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  salveazaModifica() {
    const formLocal = this.detaliiUtilizatorForm.getRawValue();

    let detaliiUtilizator: DetaliiUtilizator = {
      utilizatorId: this.utilizatorId.value,
      nume: this.nume.value,
      email: this.email.value,
      telefon: this.telefon.value,
      cheieAutorizareUpload: this.utilizatorulPoateIncarcaKitDeInstalareAutomat ? formLocal.cheieDeAutorizare.slice(1, -1) : null,
      numeUtilizatorUpload: this.utilizatorulPoateIncarcaKitDeInstalareAutomat ? formLocal.numeleUtilizatoruluiInArhiva : null,
      flaguriSecuritate: this.convert(),
      flaguriSecuritatePosibile: this.flaguriSecuritatePosibile,
    }
    
    this.utilizatoriService.AdaugaModificaUtilizator(detaliiUtilizator).subscribe();
    this.dialogRef.close(true);
  }

}
