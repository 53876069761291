/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, catchError as __catchError, finalize as __finalize } from 'rxjs/operators';
import { ApiEndpointService, AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AsambleazaIncarcaRequest } from '../models/asambleaza-incarca-request';
import { RaspunsTestIncarcareFisier } from '../models/raspuns-test-incarcare-fisier';
@Injectable({
  providedIn: 'root',
})
class StreamService extends __BaseService {
  static readonly AsambleazaSiUploadFisierPath = '/api/Stream/AsambleazaSiUploadFisier';
  static readonly IconPath = '/api/imagini/{md5}/{filename}';
  static readonly IncarcaFisierPath = '/api/Stream/IncarcaFisier';
  static readonly UploadChunkPath = '/api/Stream/UploadChunk';
  static readonly VerificaStatusJobPath = '/api/Stream/VerificaStatusJob';

  constructor(
    config: __Configuration,
    http: HttpClient,
    user: BazaUserService,
    apiEndpoint: ApiEndpointService,
	at: AtentionareAcsService,
  private router: Router
  ) {
    super(config, http, user, apiEndpoint, at);
  }

  /**
   * @param AsambleazaSiUploadFisierBodyParam
   * @return Success
   */
  AsambleazaSiUploadFisierResponse(AsambleazaSiUploadFisierBodyParam: AsambleazaIncarcaRequest, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AsambleazaSiUploadFisierBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Stream/AsambleazaSiUploadFisier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    const cod = 'AsambleazaSiUploadFisier' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param AsambleazaSiUploadFisierBodyParam
   * @return Success
   */
  AsambleazaSiUploadFisier(AsambleazaSiUploadFisierBodyParam: AsambleazaIncarcaRequest, faraProgressBar?: boolean): __Observable<string> {
    return this.AsambleazaSiUploadFisierResponse(AsambleazaSiUploadFisierBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `StreamService.IconParams` containing the following parameters:
   *
   * - `md5`:
   *
   * - `w`:
   *
   * - `h`:
   *
   * - `filename`:
   *
   */
  IconResponse(params: StreamService.IconParams, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.w != null) __params = __params.set('w', params.w.toString());
    if (params.h != null) __params = __params.set('h', params.h.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/imagini/${params.md5}/${params.filename}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'Icon' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `StreamService.IconParams` containing the following parameters:
   *
   * - `md5`:
   *
   * - `w`:
   *
   * - `h`:
   *
   * - `filename`:
   *
   */
  Icon(params: StreamService.IconParams, faraProgressBar?: boolean): __Observable<null> {
    return this.IconResponse(params, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `StreamService.IncarcaFisierParams` containing the following parameters:
   *
   * - `milisecundeLastUpdated`:
   *
   * - `idProdus`:
   *
   * - `descriereFisier`:
   *
   * - `fisier`:
   *
   * @return Success
   */
  IncarcaFisierResponse(params: StreamService.IncarcaFisierParams, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<RaspunsTestIncarcareFisier>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.milisecundeLastUpdated != null) __params = __params.set('milisecundeLastUpdated', params.milisecundeLastUpdated.toString());
    if (params.idProdus != null) __params = __params.set('idProdus', params.idProdus.toString());
    if (params.descriereFisier != null) __params = __params.set('descriereFisier', params.descriereFisier.toString());
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if(params.fisier !== null && typeof params.fisier !== "undefined") {
      __formData.append('fisier', params.fisier as string | Blob);
    }
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Stream/IncarcaFisier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'IncarcaFisier' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RaspunsTestIncarcareFisier>;
      })
    );
  }

  /**
   * @param params The `StreamService.IncarcaFisierParams` containing the following parameters:
   *
   * - `milisecundeLastUpdated`:
   *
   * - `idProdus`:
   *
   * - `descriereFisier`:
   *
   * - `fisier`:
   *
   * @return Success
   */
  IncarcaFisier(params: StreamService.IncarcaFisierParams, faraProgressBar?: boolean): __Observable<RaspunsTestIncarcareFisier> {
    return this.IncarcaFisierResponse(params, faraProgressBar).pipe(
      __map(_r => _r.body as RaspunsTestIncarcareFisier)
    );
  }

  /**
   * @param fisiere
   */
  UploadChunkResponse(fisiere: Array<any>, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if(fisiere !== null && typeof fisiere !== "undefined") {
      fisiere.forEach(f => {
        __formData.append('fisiere', f, f.name);
      });
    }
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Stream/UploadChunk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'UploadChunk' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param fisiere
   */
  UploadChunk(fisiere: Array<any>, faraProgressBar?: boolean): __Observable<null> {
    return this.UploadChunkResponse(fisiere, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param idProces
   * @return Success
   */
  VerificaStatusJobResponse(idProces?: string, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<0 | 1 | 2 | 3>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idProces != null) __params = __params.set('idProces', idProces.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Stream/VerificaStatusJob`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'VerificaStatusJob' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<0 | 1 | 2 | 3>;
      })
    );
  }

  /**
   * @param idProces
   * @return Success
   */
  VerificaStatusJob(idProces?: string, faraProgressBar?: boolean): __Observable<0 | 1 | 2 | 3> {
    return this.VerificaStatusJobResponse(idProces, faraProgressBar).pipe(
      __map(_r => _r.body as 0 | 1 | 2 | 3)
    );
  }

}

module StreamService {

  /**
   * Parameters for Icon
   */
  export interface IconParams {
    md5: string;
    w?: number;
    h?: number;
    filename: string;
  }

  /**
   * Parameters for IncarcaFisier
   */
  export interface IncarcaFisierParams {
    milisecundeLastUpdated?: number;
    idProdus?: number;
    descriereFisier?: string;
    fisier: any;
  }
}

export { StreamService }