import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RefFisierInArhiva } from 'src/app/backendapi/models';
import { FisierService } from 'src/app/backendapi/services';
import { DialogUniversalComponent } from 'src/app/utils/dialogs/dialog-universal/dialog-universal.component';

@Component({
  selector: 'app-arhiva-fisier',
  templateUrl: './arhiva-fisier.component.html',
  styleUrls: ['./arhiva-fisier.component.scss']
})
export class ArhivaFisierComponent implements OnInit {

  @Input() fisier: RefFisierInArhiva = {};
  @Input() idProdusAfisat: number;
  @Input() imagine: string = '';
  @Input() segmentUrl: string[] = [];
  @Input() modVizualizarePanou: boolean = false;
  @Input() okPunLaLocBIfaBeta: boolean;
  @Input() okEliminBifaBeta: boolean;

  constructor(private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private fisierService: FisierService
  ) { }

  ngOnInit(): void {
    // console.table(this.fisier)
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  eliminaBeta() {
    console.log('Ajung in eliminaBeta')
    console.log('okPunLaLocBIfaBeta', this.okPunLaLocBIfaBeta);
    if (!this.okEliminBifaBeta) {
      console.log('Nu aveti dreptul sa eliminati BETA')
      return;
    }

    const dlgConfig = new MatDialogConfig();

    let text: string;
    if (this.fisier.badBeta) {
      text = 'Eliminati DEFECT (fisierul devine RELEASE) de pe fisierul ' +
        this.fisier.numeFisier + ' ' +
        this.fisier.versiune + ' ' +
        this.fisier.data;
    } else {
      text = 'Eliminati BETA de pe fisierul ' +
        this.fisier.numeFisier + ' ' +
        this.fisier.versiune + ' ' +
        this.fisier.data + '.' +
        ' Marchez produsul ca fiind Release?';
    }

    dlgConfig.data = {
      header_text: "Dialog de confirmare",
      text: text,
      textButonStanga: 'Nu',
      textButonDreapta: 'Da'
    };

    this.matDialog.open(DialogUniversalComponent, {
      data: dlgConfig,
      autoFocus: false,
      minWidth: '420px'      
    }).afterClosed().subscribe(rezultat => {
      if (!!rezultat) {
        this.fisierService.EliminaBeta(this.fisier.idDownload)
          .subscribe(_ => {
            this.fisier.beta = false;
          })
      }
    })
  }

  butonRelease() {
    const text = 'Doriti sa marcati fisierul ' +
      this.fisier.numeFisier + ' ' +
      this.fisier.versiune + ' ' +
      this.fisier.data + ' ' +
      'din nou ca fiind Beta?'

    const dlgConfig = new MatDialogConfig();
    dlgConfig.data = {
      header_text: "Dialog de confirmare",
      text: text,
      textButonStanga: 'Nu',
      textButonDreapta: 'Da'
    };

    this.matDialog.open(DialogUniversalComponent, {
      data: dlgConfig,
      autoFocus: false,
      minWidth: '420px',
      maxWidth: '560px' 
    }).afterClosed().subscribe(rezultat => {
      if (!!rezultat) {
        this.fisierService.AdaugaBeta(this.fisier.idDownload)
          .subscribe(_ => {
            this.fisier.beta = true;
            this.fisier.badBeta = true;
          })
      }
    })
  }

}
