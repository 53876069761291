import { RefFisierInArhiva } from './../backendapi/models/ref-fisier-in-arhiva';
import { DialogPanouSelectComponent, ElementSelectat } from './../utils/dialogs/dialog-panou-select/dialog-panou-select.component';
import { BazaUserService, AtentionareAcsService } from '@acs/infrastructura-standard';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of, Subscription } from 'rxjs';
import { SolicitareIco, SolicitareArhiva, ViewArhiva, RefFolderInArhiva, DetaliiPanou } from '../backendapi/models';
import { Apis } from '../backendapi/resolver-universal';
import { AuthService, ArhivaService, CfgPanouService } from '../backendapi/services';
import { UserService } from '../services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-editare-panou',
  templateUrl: './editare-panou.component.html',
  styleUrls: ['./editare-panou.component.scss']
})
export class EditarePanouComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  @ViewChild('username') _usernameRef: ElementRef;
  currentLocation: string = document.location.pathname;
  viewArhiva: ViewArhiva;
  segmentUrl: string[];
  foldere: RefFolderInArhiva[] = [];
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.foldere, event.previousIndex, event.currentIndex);
  }

  constructor(
    public userService: UserService,
    public bazaUserService: BazaUserService,
    private mesaj: AtentionareAcsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public msalService: MsalService,
    private matDialog: MatDialog,
    private arhivaService: ArhivaService,
    private panouService: CfgPanouService
  ) { 
    this.subscription = this.bazaUserService.ExistaJwtValid$.subscribe(e => {
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.segmentUrl = [];

    this.panouService.ListaPanouriDefinite({formatIco: [{w: 32, h:32}, {w: 48, h:48}]}).subscribe(lista => {
      console.log('Am obtinut lista cu panourile definite', lista);
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFocus() {
    setTimeout(() => {
      this._usernameRef.nativeElement.focus()
    }, 1);
  }

  deschideDialog(tipulDeSelect?: string) {

    const pathFromRoot: string[] = [];
    const formatIcoHeader: SolicitareIco[] = [
      {
        w: 128,
        h: 128
      },
      {
        w: 64,
        h: 64
      },
    ];
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 64,
        h: 64
      }
    ];
    const solicitareArhiva: SolicitareArhiva = {
      pathFromRoot,
      formatIcoHeader,
      formatIcoContinut
    };
    this.arhivaService.CitesteArhiva(solicitareArhiva).subscribe((viewArhiva: ViewArhiva) => {
      console.log('#2 viewArhiva', viewArhiva);
      this.viewArhiva = viewArhiva;

      const dlgConfig = new MatDialogConfig();
      dlgConfig.data = {
        data: viewArhiva,
        tipDeSelect: tipulDeSelect,
        listaPanouri: ['panou1', 'panou2', 'panou3']
      }
      console.log('deschideDialog', );
  
      this.matDialog.open(DialogPanouSelectComponent, {
        data: dlgConfig.data,
        autoFocus: false,
        panelClass: 'dialog-class-detalii-parinte',
        width: '70vw',
        height: 'auto',
        minHeight: '500px'
      }).afterClosed().subscribe((e: any) => {
        if (!!e) {
          if (!isNaN(e)) {
            console.log('Este fisier', e)
          } else {
            console.log('Este folder', e)
          }
        }
      })
    })

  }

  public AdaugaPanouDemo(): void
  {
    console.log('Adaug un panou demo');
    
    // In esenta aici incerc sa demonstrez ca functioneaza API-ul pentru adaugat/editat un Panou, nimic mai mult
    // Vreau sa adaug un panou nou, asta inseamna ca trebuie sa citesc lista cu panorile existene sa ma asigur ca panoul
    // chiar e nou
    this.panouService.ListaPanouriDefinite({formatIco: []}).subscribe(definiteInitial => {
      
      console.log('Panouri definite initial: ', definiteInitial);
      
      // Aleg un nume nou de panou, de forma: "panouDemo1234"; Pornesc cu un numar, incrementez pana cand numele nu mai apare
      // in definiteInitial
      let sufix = 1
      while(definiteInitial.some(p => p.panouId === ('panouDemo' + sufix.toString())))
      {
        sufix = sufix + 1;
      }
      const panouId = 'panouDemo' + sufix.toString();
      console.log('Panoul nou va avea id: ' + panouId);

      const detaliiPanouNou: DetaliiPanou = {};
      detaliiPanouNou.panouId = panouId;
      detaliiPanouNou.descriere = 'Panou nou adaugat folosind butonul Demo';
      detaliiPanouNou.elementeInPanou = []
      detaliiPanouNou.administratori = []
      
      // Void adauga 5 elemente
      detaliiPanouNou.elementeInPanou.push({
        idProdusAfisat: 23, // eXpert Bugetar 2010
      });

      detaliiPanouNou.elementeInPanou.push({
        idProdusAfisat: 44, // Centralizator bugete 2012
      });

      detaliiPanouNou.elementeInPanou.push(
        {
          idDownloadAfisat: 528 // Centralizator bugete 2012, versiunea 2011.12.13.3112
        }
      )

      // Adaug efectiv panoul nou
      this.panouService.AdaugaSauModificaPanou(detaliiPanouNou).subscribe(() => {
        console.log('Am adaugat panoul cu succes')
      })

    });

  }
}
