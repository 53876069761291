import { Component, ElementRef, Inject, OnInit, ViewChild, NgModule, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DetaliiAcl, DetaliiProdus, SolicitareAdaugaUtilizatorACL, UtilizatorSimplificat } from 'src/app/backendapi/models';
import { ArhivaService, UtilizatoriService } from 'src/app/backendapi/services';
import { DialogUtilizatoriPentruDialogModificaFolderComponent } from '../dialog-utilizatori-pentru-dialog-modifica-folder/dialog-utilizatori-pentru-dialog-modifica-folder.component';

@Component({
  selector: 'app-dialog-modifica-folder',
  templateUrl: './dialog-modifica-folder.component.html',
  styleUrls: ['./dialog-modifica-folder.component.scss']
})
export class DialogModificaFolderComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  @ViewChild('denumireFocus', {static: false}) _denumireeRef: ElementRef;
  @ViewChild('iconitaCamera', { static: false }) _iconitaCameraRef: ElementRef;
  @ViewChild('matTab1Group') public _matTab1GroupRef: any;

  detaliiProdus: DetaliiProdus;
  color = 'secondary';
  imagineModificata = false;
  imagineHeaderInvalida = false;
  imagineSetupInvalida = false;
  detaliiAclModificat = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogModificaFolderComponent>,
    private matDialog: MatDialog,
    private utilizatoriService: UtilizatoriService,
    private arhivaService: ArhivaService
  ) {
    this.detaliiProdus = data.detaliiProdus as DetaliiProdus;
    console.log('Dialog - detaliiProdus', this.detaliiProdus);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._denumireeRef.nativeElement.focus();
    });
  }

  ngOnInit(): void {
  }


  onFileChanged(event: any, campImagine: string) {
    this.imagineHeaderInvalida = false;
    this.imagineSetupInvalida = false;

    const fileList: File[] = event.target.files;
    if (fileList && fileList.length == 1 && (/\.(jpe?g|tiff?|png|webp|bmp|ico)$/i).test(fileList[0].name)) {
      var reader = new FileReader();
      reader.readAsBinaryString(fileList[0]);
      reader.onload = (ev) => { 
        const stringBase64 = btoa(ev.target.result as string);

        if (campImagine === "imaginiHeader") {
          this.detaliiProdus.modificaImagineBase64 = stringBase64;
        } else if (campImagine === "imagineSetup") {
          this.detaliiProdus.modificaImagineSetupBase64 = stringBase64;
        }

        this.imagineModificata = true;
      }
    } else {
      if (campImagine === "imaginiHeader") {
        this.imagineHeaderInvalida = true;
      } else if (campImagine === "imagineSetup") {
        this.imagineSetupInvalida = true;
      }
      // TODO: un popup sau alert cu eroarea in ui
      console.log('Imaginea nu este valida.', )
    }
  }

  modificaValoarea(descriere: string) {
    console.log('descriere', descriere)
  }

  adaugaUtilizator(detaliiAcl: DetaliiAcl) {
  
    this.utilizatoriService.ListaUtilizatoriSimplificata().subscribe((listaUtilizatori: UtilizatorSimplificat[]) => {
      const dlgConfig = new MatDialogConfig();
      dlgConfig.data = listaUtilizatori;
      
      console.log('detaliiAcl', detaliiAcl)
      console.log('listaUtilizatori', listaUtilizatori)

      let listaDenumire: string[] = [];
      for (const e of detaliiAcl.utilizatoriAcl) {
        if (!!e) {
          listaDenumire.push(e.denumire);
        }
      }

      dlgConfig.data = listaUtilizatori
        .filter((e: UtilizatorSimplificat) => !!e.nume)
        .filter((e: UtilizatorSimplificat) => !listaDenumire.includes(e.nume));
      
      this.matDialog.open(DialogUtilizatoriPentruDialogModificaFolderComponent, {
        data: dlgConfig.data,
        autoFocus: false,
        minWidth: '620px',
        panelClass: 'dialog-class-detalii-parinte',
      }).afterClosed().subscribe((utilizatorSimplificat: UtilizatorSimplificat) => {
        if (!!utilizatorSimplificat) {
          console.log('SUNT POZITIV', utilizatorSimplificat);

          const utilizatorAcl: SolicitareAdaugaUtilizatorACL = {
            idProdus: this.detaliiProdus.idProdus,
            query: detaliiAcl.query,
            utilizatorId: utilizatorSimplificat.utilizatorId
          }
          this.arhivaService.AdaugaUtilizatorLaAcl(utilizatorAcl).subscribe(acl => {
            detaliiAcl.utilizatoriAcl.push(acl);
          });

          console.log('this.detaliiProdus', this.detaliiProdus)
        }
      })
    })
  }

  validareCampuri(
    denumireProdus: any, 
    descriereInListaProdusuluiParinte: any, 
    descriereProdusCandListezProdusul: any, 
    folderSiteCandCompunUrl: any, 
    retPolicyBadRelease: any, 
    retPolicyBeta: any,
    detaliiAclModificat: boolean
  ) {
    let e: boolean = !denumireProdus.valid || 
    !descriereInListaProdusuluiParinte.valid || 
    !descriereProdusCandListezProdusul.valid ||
    !folderSiteCandCompunUrl.valid;
    
    if (!e) {

      // daca modificam select-ul
      if (detaliiAclModificat) return false;

      if (denumireProdus.dirty || 
        descriereInListaProdusuluiParinte.dirty || 
        descriereProdusCandListezProdusul.dirty || 
        folderSiteCandCompunUrl.dirty || 
        retPolicyBadRelease.dirty || 
        retPolicyBeta.dirty ||
        this.imagineModificata 
        ) {
          return e;
        }
    }
    return !e;
  }

  salveaza() {
    console.log('this.detaliiProdus', this.detaliiProdus)
    this.arhivaService.ModificaProdus(this.detaliiProdus).subscribe(e => {
      console.log('e', e)
      this.dialogRef.close('modificat');
    })
  }

}
