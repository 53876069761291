import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {

  // test
  iframeTest = '<iframe style="width: 100%; height: 100vh" src="https://download.adicomsoft.ro/api/CfgPanou/Frame/panouDemo3?latimeFrame=100" frameborder="0"></iframe>';
  objectTest = '<object width="600" height="400" data="https://download.adicomsoft.ro/api/CfgPanou/Frame/panouDemo3?latimeFrame=100"></object>';
  
  panouId: string;
  object: string;
  
  objectTest2 = '<object width="600" height="400" data="https://test.adicomsoft.ro/hack_tabel_downloads.aspx"></object>';

  STIL: string = '';
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.panouId = params.get('id')
      console.log('this.panouId', this.panouId)
      // https://localhost:44372/api/CfgPanou/Frame/aaaaaaaa?latimeFrame=400

      this.initOject();
    });
  }

  initOject() {
    const latimeFrame = 180;
    // acest prop nu se trimite la api
    const inaltimeFrameLocal = 860
    
    let path = 'data="https://localhost:44372/api/CfgPanou/Frame/'+ this.panouId + 
      '?latimeFrame=' + (latimeFrame-25) + 
      (!!this.STIL ? ('&stil=' + this.STIL) : '') + '"'

    this.object = 
      '<object width= "' + 
      latimeFrame + '" height="' + 
      inaltimeFrameLocal + '"' + path +
      '></object>';
  }
  
  aplicaSTIL() {
    this.STIL = 'Sidebar2011';
    this.initOject();
  }
  
  eliminaSTIL() {
    this.STIL = '';
    this.initOject();
  }


  public fisierSelectat: BehaviorSubject<File> = new BehaviorSubject<File>(null);
  public fisierSelectat$: Observable<File> = this.fisierSelectat.asObservable();
  
  public uploadFile = (files: File[]) => {

    console.log('#1', files)
    
    if (files.length === 0) {
      return;
    }

    // doar fisierele .zip, .exe sunt permise 
    if ((/\.(zip|exe)$/i).test(files[0].name)) {
      console.log('este .zip|exe', )
      
      let fileToUpload: File = files[0];
      
      this.fisierSelectat.next(files[0]);
      console.log('this.fisierSelectat', this.fisierSelectat.getValue())
  
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
  
      console.log('formData', formData)
      console.log('fileToUpload', fileToUpload)
      
      // this.http.post('https://localhost:5001/api/upload', formData, {reportProgress: true, observe: 'events'})
      //   .subscribe(event => {
      //     if (event.type === HttpEventType.UploadProgress)
      //       this.progress = Math.round(100 * event.loaded / event.total);
      //     else if (event.type === HttpEventType.Response) {
      //       this.message = 'Upload success.';
      //       this.onUploadFinished.emit(event.body);
      //     }
      //   });
    }
  }

}
