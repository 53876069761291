<app-cu-meniu>
  
  <br>

  <div class="container">

    <div 
      fxLayout="row" 
      fxLayoutAlign="start center" 
      fxLayoutGap="10px"
      id="head"
    >
      <!-- img -->
      <div fxLayout="column" fxLayoutAlign="start stretch">
          
        <ng-container *ngIf="imagineInvalida">
          <p style="color: red; font-size: 12px;">
            <span style="position: relative; left: 7px;">Imagine invalidă</span>
            <mat-icon
              style="color: rgb(255, 94, 94); position: relative; bottom: -16px; left: 7px; z-index: 9; cursor: help;"
              [matTooltip]="'Format imagine suportat: jpe/g, tiff, png, webp, bmp, ico.'"
            >help</mat-icon>
          </p>
        </ng-container>
      
        <div [class.borderEroareImagine]="imagineInvalida">
          <input 
            type="file"
            name="image"
            id="targetInputFile"
            (change)="onFileChanged($event)"
            style="display: none;"
          >
          
          <label for="targetInputFile">
            <!-- incerc sa folosesc imaginea din property-ul imagineBase64 -->
            <img
              src="{{!!detaliiPanou.imagineBase64 ? ('data:image/png;base64,' + detaliiPanou.imagineBase64) : detaliiPanou.urlImagine[0]}}"
              width= 92px 
              height= 92px
              alt="imagine-viewArhiva-https"
              class="modifica_ico"
            >
            
            <p
              fxLayout="row"
              fxLayoutAlign="center center"
              (change)="onFileChanged($event)"
              class="text_schimba_imaginea"
            >Schimba Imaginea</p>
          </label>
        </div>
      </div>

      <div fxLayout="column" fxFlex>
        
        <!-- id + administratori -->
        <div fxLayout="row" fxLayoutGap="15px">

          <div fxLayoutGap="10px" fxLayoutAlign="start end" [fxFlex]="bazaUserService.listaRoluri.includes('Admin') ? '30' : '100'" >
            <mat-form-field fxFlex>
              <mat-label>Id</mat-label>
              <input
                matInput
                [(ngModel)]="detaliiPanou.panouId"
                #panouId="ngModel"
                type="text"
                required
              >
              <mat-error>Introduceţi o valoare</mat-error>
            </mat-form-field>
          </div>

          <mat-form-field class="example-chip-list" fxFlex *ngIf="bazaUserService.listaRoluri.includes('Admin')">
            <mat-label>Administrator delegati</mat-label>
            <mat-chip-list #chipList >
              <mat-chip
                *ngFor="let utilizatorNumeSelectat of this.listaUtilizatoriSimplificatNumeSelectati"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(utilizatorNumeSelectat)">
                {{utilizatorNumeSelectat}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Administrator nou..."
                #administratorInput
                [formControl]="UtilizatoriFormCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
              >
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let e of filteredAdministrator | async" [value]="e" [disabled]="this.listaUtilizatoriSimplificatNumeSelectati.includes(e)">
                {{e}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">

          <!-- descrirere -->
          <mat-form-field fxFlex>
            <mat-label>Descriere</mat-label>
            <input
              matInput
              [(ngModel)]="detaliiPanou.descriere"
              #descriere="ngModel"
              type="text"
              required
            >
            <mat-error>Introduceţi o valoare</mat-error>
          </mat-form-field>

          <div [matTooltip]="valoriModificate() ? 'Panoul nu poate fi salvat deoarece nu prezinta modificari' : ''">
            <!-- salveaza -->
            <button 
              mat-raised-button 
              (click)="$event.target.blur(); salveazaModificariPanou();"
              style="color: gray;"
              >
              <!-- [disabled]="valoriModificate()" -->
              Salvează
            </button>
          </div>

        </div>

      </div>
      
    </div>

    <br>

    <!-- Butoane -->
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="10px">
        <button 
          mat-stroked-button 
          style="width: 215px;line-height: 1;"
          (click)="adaugaElement('fisier'); $event.target.blur();"
        >
          <span style="white-space: pre-line;">
            Adaugă fisier explicit
            (versiunea nu se schimba, se pot selecta fisiere Beta)
          </span>
        </button>
  
        <button 
          mat-stroked-button 
          (click)="adaugaElement('produs'); $event.target.blur();"
          style="width: 255px;line-height: 1;"
        >
          <span style="white-space: pre-line;">
            Adaugă cel mai recent Release pentru un produs
            (se actualizeaza automat daca se incarca un nou produs)
          </span>
        </button>
      </div>

      <!-- <div>
        <button mat-stroked-button (click)="log(); $event.target.blur();">log</button>
      </div> -->
    </div>

    <br>

    <!-- Content -->
    <ng-container *ngIf="detaliiPanou?.elementeInPanou">

      <div id="content">
        <p>Elemente panou: </p>
        <div cdkDropList class="list-modEditat" (cdkDropListDropped)="drop($event)">
          
          <div class="box-modEditat" *ngFor="let fisier of detaliiPanou.elementeInPanou; let i = index" cdkDrag>
            <div class="custom-placeholder" *cdkDragPlaceholder></div>
            
            <app-panou-element
              [fisier]="fisier.fisierIdentificat"
              [idProdusAfisat]="fisier.idProdusAfisat"
              [imagine]="fisier.fisierIdentificat.ico[0]"
              (stergereElement)="stergereElement(i)"
              (inlocuiesteElement)="inlocuiesteElement($event, i)"
              [(overrideNumeFisier)]="fisier.overrideNumeFisier"
            ></app-panou-element>
  
            <mat-divider></mat-divider>
  
          </div>
  
        </div>
      </div>
    </ng-container>

    
  </div>

</app-cu-meniu>