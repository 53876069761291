<div 
  [routerLink]="[location, folder.folderSite]"
  class="folder"
  (mouseover)="hovered = true" 
  (mouseout)="hovered = false"
>
  <div fxLayout="space-between" fxLayoutGap="10px">
    <div 
      fxLayout="row" 
      fxLayoutAlign="start center"
    >
      <img 
        [src]="!!folder.ico[0] ? folder.ico[0] : imagine"
        alt="imagine-folder"
        style="z-index: -1;"
      >
      <div fxLayout="column" style="margin-left: 10px;">
        <p style="font-weight: 400">
          {{folder.denumire}} 
        </p>
        <p style="margin-right: 20px;">
          {{folder.explicatii}}
        </p>
      </div>
    </div>

    <div fxFlex></div>
      <button 
        mat-icon-button 
        (click)="$event.stopPropagation(); dialogModificaFolder()"
      >
      <ng-container *ngIf="bazaUserService.ExistaJwtValid$ | async">
        <ng-container *ngIf="folder.okEditor">
          <mat-icon [ngClass]="(hovered) ? 'm-fadeIn' : 'm-fadeOut'">
            edit
          </mat-icon>
        </ng-container>
      </ng-container>
      </button>
  </div>
</div>