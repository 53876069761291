<div class="container_dialog">
  <h1 mat-dialog-title>
    <div fxLayout="column">
      <div 
        fxLayout="row"
        class="text-header-dialog"
      >
        <ng-container>
          <div fxFlex style="margin: 7px 25px 0 0;" fxLayout="row">
            <p>Editare Panou</p> - {{tipDeSelect}}
          </div>
        </ng-container>

        <div class="close-button">
          <button mat-icon-button (click)="renunta()">
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </h1>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
      <p class="breadCrumb" (click)="fetchData()">Root</p>
      <ng-container *ngFor="let folderLista of viewArhiva.folderListat; let i = index">
        <p style="color: gray;">/</p>
        <p class="breadCrumb" (click)="navigare(viewArhiva.folderListat.slice(0, i + 1))">{{folderLista}}</p>
      </ng-container>
    </div>
  </div>

  <mat-dialog-content style="outline: none; min-height: 510px;">
    <div fxLayout="column" fxFlex>
      <ng-container *ngFor="let folder of viewArhiva.foldere; let i = index">
        <div 
          (click)="modificaDirectorul(folder.folderSite)"
          (mouseover)="elementMouseHover = i"
          (mouseout)="elementMouseHover = null"
          [class.hoverElement]="elementMouseHover === i"
        >
          <div style="padding: 0 15px;">
            <app-folder-editare-panou 
              [folder]="folder"
              (determinamDacaFolderulAreFisier)="determinamDacaFolderulAreFisier(folder.folderSite, folder)"
              [tipDeSelect]="tipDeSelect"
              [elementMouseHover]="this.elementMouseHover === folder"
            ></app-folder-editare-panou>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="viewArhiva?.fisiere && tipDeSelect === 'fisier'">
        <ng-container *ngFor="let fisier of viewArhiva.fisiere">
          <div
            (mouseover)="elementMouseHover = fisier"
            (mouseout)="elementMouseHover = false"
            [class.hoverElement]="elementMouseHover === fisier"
          >
          <div style="padding: 0 15px;">
            <app-fisier-editare-panou
              [fisier]="fisier"
              (alegeFisier)="alegeFisier($event)"
              [tipDeSelect]="tipDeSelect"
            ></app-fisier-editare-panou>
          </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions fxLayoutr="row" fxLayoutAlign="start center" style="padding: 15px 0 25px 0;">
    <button 
      mat-button 
      (click)="renunta()"
      class="font_weight200"
    >Renunţă</button>
  </mat-dialog-actions>
  
</div>