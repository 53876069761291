/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, catchError as __catchError, finalize as __finalize } from 'rxjs/operators';
import { ApiEndpointService, AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { LoginRequest } from '../models/login-request';
import { LoginResponse } from '../models/login-response';
@Injectable({
  providedIn: 'root',
})
class AuthService extends __BaseService {
  static readonly LoginPath = '/api/Auth/login';
  static readonly LoginFolosindMicrosoftPath = '/api/Auth/mslogin';
  static readonly ResetParolaPersonalaPath = '/api/Auth/ResetParolaPersonala';
  static readonly ResetParolaUtilizatoruluiPath = '/api/Auth/ResetParolaUtilizatorului';
  static readonly SPingPath = '/api/Auth/SPing';

  constructor(
    config: __Configuration,
    http: HttpClient,
    user: BazaUserService,
    apiEndpoint: ApiEndpointService,
	at: AtentionareAcsService,
  private router: Router
  ) {
    super(config, http, user, apiEndpoint, at);
  }

  /**
   * @param LoginBodyParam
   * @return Success
   */
  LoginResponse(LoginBodyParam: LoginRequest, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<LoginResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = LoginBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'Login' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginResponse>;
      })
    );
  }

  /**
   * @param LoginBodyParam
   * @return Success
   */
  Login(LoginBodyParam: LoginRequest, faraProgressBar?: boolean): __Observable<LoginResponse> {
    return this.LoginResponse(LoginBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as LoginResponse)
    );
  }

  /**
   * @param token
   * @return Success
   */
  LoginFolosindMicrosoftResponse(token?: string, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/mslogin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    const cod = 'LoginFolosindMicrosoft' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param token
   * @return Success
   */
  LoginFolosindMicrosoft(token?: string, faraProgressBar?: boolean): __Observable<string> {
    return this.LoginFolosindMicrosoftResponse(token, faraProgressBar).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AuthService.ResetParolaPersonalaParams` containing the following parameters:
   *
   * - `parolaVeche`:
   *
   * - `parolaNoua`:
   *
   * @return Success
   */
  ResetParolaPersonalaResponse(params: AuthService.ResetParolaPersonalaParams, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<LoginResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parolaVeche != null) __params = __params.set('parolaVeche', params.parolaVeche.toString());
    if (params.parolaNoua != null) __params = __params.set('parolaNoua', params.parolaNoua.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/ResetParolaPersonala`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'ResetParolaPersonala' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginResponse>;
      })
    );
  }

  /**
   * @param params The `AuthService.ResetParolaPersonalaParams` containing the following parameters:
   *
   * - `parolaVeche`:
   *
   * - `parolaNoua`:
   *
   * @return Success
   */
  ResetParolaPersonala(params: AuthService.ResetParolaPersonalaParams, faraProgressBar?: boolean): __Observable<LoginResponse> {
    return this.ResetParolaPersonalaResponse(params, faraProgressBar).pipe(
      __map(_r => _r.body as LoginResponse)
    );
  }

  /**
   * @param params The `AuthService.ResetParolaUtilizatoruluiParams` containing the following parameters:
   *
   * - `UtilizatorId`:
   *
   * - `parolaNoua`:
   *
   * @return Success
   */
  ResetParolaUtilizatoruluiResponse(params: AuthService.ResetParolaUtilizatoruluiParams, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UtilizatorId != null) __params = __params.set('UtilizatorId', params.UtilizatorId.toString());
    if (params.parolaNoua != null) __params = __params.set('parolaNoua', params.parolaNoua.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/ResetParolaUtilizatorului`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    const cod = 'ResetParolaUtilizatorului' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * @param params The `AuthService.ResetParolaUtilizatoruluiParams` containing the following parameters:
   *
   * - `UtilizatorId`:
   *
   * - `parolaNoua`:
   *
   * @return Success
   */
  ResetParolaUtilizatorului(params: AuthService.ResetParolaUtilizatoruluiParams, faraProgressBar?: boolean): __Observable<boolean> {
    return this.ResetParolaUtilizatoruluiResponse(params, faraProgressBar).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param msg
   * @return Success
   */
  SPingResponse(msg?: string, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (msg != null) __params = __params.set('msg', msg.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/SPing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    const cod = 'SPing' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param msg
   * @return Success
   */
  SPing(msg?: string, faraProgressBar?: boolean): __Observable<string> {
    return this.SPingResponse(msg, faraProgressBar).pipe(
      __map(_r => _r.body as string)
    );
  }

}

module AuthService {

  /**
   * Parameters for ResetParolaPersonala
   */
  export interface ResetParolaPersonalaParams {
    parolaVeche?: string;
    parolaNoua?: string;
  }

  /**
   * Parameters for ResetParolaUtilizatorului
   */
  export interface ResetParolaUtilizatoruluiParams {
    UtilizatorId?: string;
    parolaNoua?: string;
  }
}

export { AuthService }