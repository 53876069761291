import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RefFisierInArhiva, RefFolderInArhiva } from 'src/app/backendapi/models';

@Component({
  selector: 'app-folder-editare-panou',
  templateUrl: './folder-editare-panou.component.html',
  styleUrls: ['./folder-editare-panou.component.scss']
})
export class FolderEditarePanouComponent implements OnInit {

  @Input() folder: RefFolderInArhiva = {};
  @Output() determinamDacaFolderulAreFisier = new EventEmitter<any>();
  @Input() tipDeSelect: string;
  image: string | ArrayBuffer;

  constructor() { }

  ngOnInit(): void {
  }

}
