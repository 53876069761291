<app-cu-meniu>
  
  <br>

  <div class="container">

    <div fxLayot="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button 
        mat-stroked-button 
        (click)="dialogAdaugaModificaUtilizator(); $event.target.blur();"
        style="color: gray;"
      >
        <mat-icon style="color: #3F51B5; font-size: 30px; height: 30px; width: 30px;">
          add_circle
        </mat-icon>
          Adaugă
      </button>
    </div>

    <br>

    <table mat-table [dataSource]="dataSource" style="width: 100%; display: table;">
    
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 15px;">
          <div style="display: table-cell;">
            #id
          </div>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{i + 1}}.
        </td>

      </ng-container>

      <ng-container matColumnDef="nume">
        <th mat-header-cell *matHeaderCellDef style="width: 100px;">Nume</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container>
            <p>{{ element.nume || '_' }}</p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef style="width: 115px;">E-mail</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container>
            <p>{{ element.email || '_' }}</p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="telefon">
        <th mat-header-cell *matHeaderCellDef style="width: 115px;">Telefon</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container>
            <p>{{ element.telefon || '_' }}</p>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="flagSecUtilizator">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">
          <p style="font-weight: 500; text-align: center;">Admin</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
            <ng-container *ngIf="!!element.flaguriSecuritate.includes(+0) else nuEsteAdmin">
              <div fxLayout="column" fxLayoutAlign="center center">
                <mat-icon style="color: green; font-size: 21px;">
                  check_circle_outline
                </mat-icon>
              </div>
            </ng-container>
            <ng-template #nuEsteAdmin>
              <div fxLayout="column" fxLayoutAlign="center center">
                <mat-icon style="color: rgba(214, 214, 214, 0.596); font-size: 21px;">
                  check_circle_outline
                </mat-icon>
              </div>
            </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="flagSecAdmin">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">
          <p style="font-weight: 500; text-align: center;">Utilizator</p>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container *ngIf="!!element.flaguriSecuritate.includes(+1); else nuEsteUtilizator">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon style="color: green; font-size: 21px;">
                check_circle_outline
              </mat-icon>
            </div>
          </ng-container>
          <ng-template #nuEsteUtilizator>
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon style="color: rgba(214, 214, 214, 0.596); font-size: 21px;">
                check_circle_outline
              </mat-icon>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="actiuni">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">
          <p style="font-weight: 500; text-align: center;">Actiuni</p>
        </th>      <td mat-cell *matCellDef="let element; let i = index">
          <div fxLayout='row' fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">

              <button mat-icon-button (click)="dialogAdaugaModificaUtilizator(element)">
                <mat-icon class="iconita_edit" >border_color</mat-icon>
              </button>

              <button mat-icon-button (click)="stergeUtilizator(element);">
                <mat-icon class="iconita_sterge">delete_outline</mat-icon>
              </button>

              <button mat-icon-button (click)="resetareParola(element);">
                <mat-icon class="iconita_sterge">vpn_key</mat-icon>
              </button>

            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr
        mat-row
        *matRowDef="let row; let i = index; columns: displayedColumns"
      ></tr>
    </table>

  </div>

</app-cu-meniu>