import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DetaliiUtilizator, UtilizatorSimplificat } from 'src/app/backendapi/models';

@Component({
  selector: 'app-dialog-utilizatori-pentru-dialog-modifica-folder',
  templateUrl: './dialog-utilizatori-pentru-dialog-modifica-folder.component.html',
  styleUrls: ['./dialog-utilizatori-pentru-dialog-modifica-folder.component.scss']
})
export class DialogUtilizatoriPentruDialogModificaFolderComponent implements OnInit {

  detaliiUtilizator: UtilizatorSimplificat[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UtilizatorSimplificat[],
    public dialogRef: MatDialogRef<DialogUtilizatoriPentruDialogModificaFolderComponent>,
    private matDialog: MatDialog,
  ) {
    this.detaliiUtilizator = data.filter((e: UtilizatorSimplificat)=> !!e.nume);
    console.log('Dialog - detaliiUtilizator', this.detaliiUtilizator)
  }

  ngOnInit(): void {
  }

  continua(utilizatorSimplificat: UtilizatorSimplificat) {
    console.log('utilizator', utilizatorSimplificat);
    this.dialogRef.close(utilizatorSimplificat);
  }
}
