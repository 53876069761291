<h1 mat-dialog-title style="margin-bottom: -7px !important">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxFlex style="margin: 7px 25px 0 0;">
        <p>Cheie upload</p>
      </div>
        <mat-icon [mat-dialog-close]="false" style="color: gray; cursor: pointer;">close</mat-icon>
    </div>
  </div>
</h1>

<br>

<!-- style="outline: none; min-height: 510px;" -->
<mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button 
      mat-icon-button 
      style="margin-bottom: 7px;"
      (click)="cheie; openSnackBar('Cheia a fost salvata in clipboard.', ''); $event.stopPropagation();" 
    >
      <mat-icon 
        matPrefix
        [cdkCopyToClipboard]="cheie"
        class="color-gri"
        style="font-size: 17px;"
      >content_copy</mat-icon>
    </button>

    <mat-form-field fxFlex>
      <mat-label>Cheie</mat-label> 
      <input
        matInput
        [(ngModel)]="cheie"
        type="text"
        required
        (click)="$event.target.select()"
      >
      <mat-error>Introduceţi o valoare</mat-error>

      <span matSuffix><mat-icon class="color-gri" style="font-size: 17px;">password</mat-icon></span>
    </mat-form-field>

  </div>
</mat-dialog-content>

<div fxLayout="column">
  <mat-dialog-actions fxLayout="row" style="margin: 10px 0 -8px 0;">
    <button
      fxFlex
      mat-button
      class="buton-renunt"
      mat-dialog-close
    ><p>Renunţă</p></button>

    <button
      fxFlex
      mat-stroked-button
      class="buton-continua"
      (click)="salveaza()"
    >
      Salvează
    </button>
  </mat-dialog-actions>
</div>