import { EmptyGUID } from './../../../boilerplate/constante.comune';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { SolicitarePanou, DetaliiPanou, UtilizatorSimplificat, SolicitareIco, SolicitareArhiva, ViewArhiva, PanouElementModel, RefFisierInArhiva } from 'src/app/backendapi/models';
import { Apis } from 'src/app/backendapi/resolver-universal';
import { ArhivaService, CfgPanouService, StreamService, UtilizatoriService } from 'src/app/backendapi/services';
import { UserService } from 'src/app/services/user.service';
import { DialogPanouSelectComponent } from 'src/app/utils/dialogs/dialog-panou-select/dialog-panou-select.component';


@Component({
  selector: 'app-panouri-edit',
  templateUrl: './panouri-edit.component.html',
  styleUrls: ['./panouri-edit.component.scss']
})
export class PanouriEditComponent implements OnInit {

  public static ResolveUniversal(param: Params, queryParams: Params, apis: Apis, userService: UserService): Observable<any> {

    const solicitareListaPanouri: SolicitarePanou = {
      formatIcoPanou: [
        {
          "w": 128,
          "h": 128
        }
      ],
      formatIcoElement: [
        {
          "w": 128,
          "h": 128
        }
      ],
      panouId: param['panouId']
    };
    return apis.cfgPanou.DetaliiPanou(solicitareListaPanouri);
  }

  detaliiPanou: DetaliiPanou;
  imagineModificata = false;
  imagineInvalida = false;
  myControl = new FormControl();
  options: UtilizatorSimplificat[] = [];
  filteredOptions: Observable<UtilizatorSimplificat[]>;

  listaUtilizatorSimplificat: UtilizatorSimplificat[] = [];
  listaUtilizatoriSimplificatNume: string[] = [];
  listaUtilizatoriSimplificatNumeSelectati: string[] = [];

  // variabile folosite pentru a face posibila salvarea panoului
  // doar panoul ce prezinta campuri modificare poate apela metoda de salvare
  elementeInPanouInitial: PanouElementModel[];
  listaUtilizatoriSimplificatNumeSelectatiInitial: string[] = [];
  detaliiPanouInitial: DetaliiPanou;
  
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  UtilizatoriFormCtrl = new FormControl();
  filteredAdministrator: Observable<string[]>;

  @ViewChild('administratorInput') administratorInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  panouModificat = false;

  constructor(
    private route: ActivatedRoute,
    private arhivaService: ArhivaService,
    private matDialog: MatDialog,
    private panouService: CfgPanouService,
    private router: Router,
    private atentionareAcsService: AtentionareAcsService,
    private utilizatoriService: UtilizatoriService,
    public bazaUserService: BazaUserService,
  ) {
    this.utilizatoriService.ListaUtilizatoriSimplificata().subscribe((listaUtilizatorSimplificat: UtilizatorSimplificat[]) => {
      this.listaUtilizatorSimplificat = listaUtilizatorSimplificat;

      for(const e of listaUtilizatorSimplificat) {
        this.options.push(e);
        this.listaUtilizatoriSimplificatNume.push(e.nume);
      }

      // for (var i=1; i<=20; i++) {
      //   this.listaUtilizatoriSimplificatNume.push('test' + i);
      // }
      
      this.filteredAdministrator = this.UtilizatoriFormCtrl.valueChanges.pipe(
        startWith(null),
        map((e: string | null) => e ? this._filter(e) : this.listaUtilizatoriSimplificatNume.slice()));
    })
  }


  add(event: MatChipInputEvent): void {
    if (this.listaUtilizatoriSimplificatNumeSelectati.includes(event.value)) {
      const input = event.input;
      const value = event.value;
  
      if ((value || '').trim()) {
        this.listaUtilizatoriSimplificatNumeSelectati.push(value.trim());
      }
  
      if (input) {
        input.value = '';
      }
  
      this.UtilizatoriFormCtrl.setValue(null);
    }
  }

  remove(e: string): void {
    const index = this.listaUtilizatoriSimplificatNumeSelectati.indexOf(e);

    if (index >= 0) {
      this.listaUtilizatoriSimplificatNumeSelectati.splice(index, 1);
    }
    console.log('this.listaUtilizatoriSimplificatNumeSelectati', this.listaUtilizatoriSimplificatNumeSelectati)
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.listaUtilizatoriSimplificatNumeSelectati.push(event.option.viewValue);
    this.administratorInput.nativeElement.value = '';
    this.UtilizatoriFormCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.listaUtilizatoriSimplificatNume.filter(e => e.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.route.data.subscribe((rezultat: any) => {
      this.detaliiPanou = rezultat.info as DetaliiPanou;
      this.detaliiPanouInitial = {... rezultat.info} as DetaliiPanou;

      if (this.detaliiPanou?.administratori?.length > 0 ) {
        for (const administrator of this.detaliiPanou.administratori) {
          this.listaUtilizatoriSimplificatNumeSelectati.push(administrator.nume);
        }
      }

      this.listaUtilizatoriSimplificatNumeSelectatiInitial = [...this.listaUtilizatoriSimplificatNumeSelectati];
      this.elementeInPanouInitial = [...this.detaliiPanou.elementeInPanou];

      console.log('#0', this.detaliiPanou)
    });
  }

  onFileChanged(event: any) {
    this.imagineInvalida = false;
    const fileList: File[] = event.target.files;
    if (fileList && fileList.length == 1 && (/\.(jpe?g|tiff?|png|webp|bmp|ico)$/i).test(fileList[0].name)) {
      var reader = new FileReader();
      reader.readAsBinaryString(fileList[0]);
      reader.onload = (ev) => { 
        const stringBase64 = btoa(ev.target.result as string);
        this.detaliiPanou.imagineBase64 = stringBase64
        console.log('Imagine base64 primele 35 charactere: ', this.detaliiPanou.urlImagine[0].slice(0, 35))
        this.imagineModificata = true;
      }
    } else {
      this.imagineInvalida = true;
      console.log('Imaginea nu este valida.', )
    }
  }

  adaugaElement(tipulDeSelect: string) {

    const pathFromRoot: string[] = [];
    const formatIcoHeader: SolicitareIco[] = [
      {
        w: 128,
        h: 128
      },
      {
        w: 64,
        h: 64
      },
    ];
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 64,
        h: 64
      }
    ];
    const solicitareArhiva: SolicitareArhiva = {
      pathFromRoot,
      formatIcoHeader,
      formatIcoContinut
    };
    this.arhivaService.CitesteArhiva(solicitareArhiva).subscribe((viewArhiva: ViewArhiva) => {

      const dlgConfig = new MatDialogConfig();
      dlgConfig.data = {
        data: viewArhiva,
        tipDeSelect: tipulDeSelect,
        listaPanouri: ['panou1', 'panou2', 'panou3']
      }
  
      this.matDialog.open(DialogPanouSelectComponent, {
        data: dlgConfig.data,
        autoFocus: false,
        panelClass: 'dialog-class-detalii-parinte',
        width: '70vw',
        height: 'auto',
        minHeight: '500px'
      }).afterClosed().subscribe((e) => {
        if (!!e) {
          if (!this.detaliiPanou.elementeInPanou) {
            this.detaliiPanou.elementeInPanou = [];
          }

          if (e.data) {
            // console.log('FISIER', e)
            let panouElementModel: PanouElementModel = {
              panouElementId: EmptyGUID,
              idDownloadAfisat: e.idDownload,
              fisierIdentificat: e
              // idProdusAfisat: null,
              // overrideNumeFisier: null,
              // imaginePersonalizataBase64: null,
              // urlImagini: null,
            }
              this.detaliiPanou.elementeInPanou.push(panouElementModel);
          } else {
            // console.log('FOLDER', e)
            const refFisierInArhiva: RefFisierInArhiva = {
              ico: [e.ico[0]],
              idDownload: 11111,
              // numeFisier: 'null',
              // versiune: 'null',
              // data: 'null',
              dimensiune: null,
              comentariu: e.explicatii,
              // beta: true,
              // downloadUrl: 'null',
              badBeta: true,
            }
  
            const panouElementModel: PanouElementModel = {
              panouElementId: EmptyGUID,
              idProdusAfisat: e.idProdus,
              fisierIdentificat: refFisierInArhiva
            }
        
            this.detaliiPanou.elementeInPanou.push(panouElementModel);
          } 
        }

      })
    })
  }

  inlocuiesteElement(tipulDeSelect: string, i: number) {

    const pathFromRoot: string[] = [];
    const formatIcoHeader: SolicitareIco[] = [
      {
        w: 128,
        h: 128
      },
      {
        w: 64,
        h: 64
      },
    ];
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 64,
        h: 64
      }
    ];
    const solicitareArhiva: SolicitareArhiva = {
      pathFromRoot,
      formatIcoHeader,
      formatIcoContinut
    };
    this.arhivaService.CitesteArhiva(solicitareArhiva).subscribe((viewArhiva: ViewArhiva) => {

      const dlgConfig = new MatDialogConfig();
      dlgConfig.data = {
        data: viewArhiva,
        tipDeSelect: tipulDeSelect,
        listaPanouri: ['panou1', 'panou2', 'panou3']
      }
  
      this.matDialog.open(DialogPanouSelectComponent, {
        data: dlgConfig.data,
        autoFocus: false,
        panelClass: 'dialog-class-detalii-parinte',
        width: '70vw',
        height: 'auto',
        minHeight: '500px'
      }).afterClosed().subscribe((e) => {
        if (!!e) {
          if (!this.detaliiPanou.elementeInPanou) {
            this.detaliiPanou.elementeInPanou = [];
          }

          if (tipulDeSelect === 'fisier') {
            let panouElementModel: PanouElementModel = {
              panouElementId: EmptyGUID,
              idDownloadAfisat: e.idDownload,
              fisierIdentificat: e
              // idProdusAfisat: null,
              // overrideNumeFisier: null,
              // imaginePersonalizataBase64: null,
              // urlImagini: null,
            }
            this.inlocuimElementul(i, panouElementModel);

          } else {
            // console.log('FOLDER', e)

            const refFisierInArhiva: RefFisierInArhiva = {
              ico: [e.ico[0]],
              idDownload: 11111,
              // numeFisier: 'null',
              // versiune: 'null',
              // data: 'null',
              dimensiune: null,
              comentariu: e.explicatii,
              // beta: true,
              // downloadUrl: 'null',
              badBeta: true,
            }

            const panouElementModel: PanouElementModel = {
              panouElementId: EmptyGUID,
              idProdusAfisat: e.idProdus,
              fisierIdentificat: refFisierInArhiva
            }

            this.inlocuimElementul(i, panouElementModel);
          }

        }

      })
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.detaliiPanou.elementeInPanou, event.previousIndex, event.currentIndex);
  }

  inlocuimElementul(i: number, panouElementModel: PanouElementModel) {
    this.detaliiPanou.elementeInPanou[i] = panouElementModel;
  }

  salveazaModificariPanou() {
    // console.log('detaliiPanou', this.detaliiPanou);
    let arrayPentruPayload: PanouElementModel[] = [];

    let detaliiPanouLocal = {...this.detaliiPanou};
    let elementeInPanouLocal = [...this.detaliiPanou.elementeInPanou];

    for (const elementPanou of elementeInPanouLocal) {
      if (!!elementPanou.fisierIdentificat.dimensiune) {
        arrayPentruPayload.push(elementPanou);
      } else {
        // delete elementPanou.fisierIdentificat;
        const panouElementModel: PanouElementModel = {
          panouElementId: EmptyGUID,
          idProdusAfisat: elementPanou.idProdusAfisat,
        }
        arrayPentruPayload.push(panouElementModel);
      }
    }
    detaliiPanouLocal.elementeInPanou = arrayPentruPayload;
    
    delete detaliiPanouLocal['fisierIdentificat'];

    // set administratori
    detaliiPanouLocal.administratori = [];
    for (const numeUtilizator of this.listaUtilizatoriSimplificatNumeSelectati) {
      console.log('numeUtilizator', numeUtilizator)
      detaliiPanouLocal.administratori.push(this.listaUtilizatorSimplificat.find(e => e.nume === numeUtilizator))
    }

    this.panouService.AdaugaSauModificaPanou(detaliiPanouLocal).subscribe((e) => {
      this.router.navigate(['panouri']);
    })
  }

  log() {
    // console.log('this.detaliiPanou', this.detaliiPanou)
  }

  stergereElement(id: number) {
    this.detaliiPanou.elementeInPanou.splice(id, 1);
    this.atentionareAcsService.Succes("Produsul a fost sters cu succes!");
  }

  valoriModificate() : boolean {
    return (
      JSON.stringify(this.detaliiPanou) === JSON.stringify(this.detaliiPanouInitial) &&
      JSON.stringify(this.detaliiPanou.elementeInPanou) === JSON.stringify(this.elementeInPanouInitial) &&
      JSON.stringify(this.listaUtilizatoriSimplificatNumeSelectati) === JSON.stringify(this.listaUtilizatoriSimplificatNumeSelectatiInitial)
    );
  }
  
}
