import { catchError, finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { BazaUserService } from '@acs/infrastructura-standard';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ArhivaService, StreamService } from 'src/app/backendapi/services';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apis } from 'src/app/backendapi/resolver-universal';
import { MsalService } from '@azure/msal-angular';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogModificaFolderComponent } from './dialog/dialog-modifica-folder/dialog-modifica-folder.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DetaliiProdus, RaspunsTestIncarcareFisier, RefFolderInArhiva, SolicitareArhiva, SolicitareDetaliiProdus, SolicitareIco, SolicitareReordonare, ViewArhiva } from '../backendapi/models';

@Component({
  selector: 'app-arhiva',
  templateUrl: './arhiva.component.html',
  styleUrls: ['./arhiva.component.scss'],
})
export class ArhivaComponent implements OnInit, AfterViewInit, OnDestroy {

  segmentUrl: string[];
  @ViewChild('scrollMe', { static: false }) public myScrollContainer: ElementRef;
  viewArhiva: ViewArhiva;
  currentLocation: string = document.location.pathname;
  private subscription: Subscription;
  modEditare = false;
  procesDeIncarcare = false;

  foldere: RefFolderInArhiva[] = [];
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.foldere, event.previousIndex, event.currentIndex);
  }

  constructor(
    public userService: UserService,
    public bazaUserService: BazaUserService,
    private route: ActivatedRoute,
    private router: Router,
    public msalService: MsalService,
    private matDialog: MatDialog,
    private arhivaService: ArhivaService,
    private streamService: StreamService,
    private snackBar: MatSnackBar
  ) { 
    this.subscription = this.bazaUserService.ExistaJwtValid$.subscribe(e => {
      this.ngOnInit();
    });
  }

  public static ResolverUniversalArhiva(listaSegmenteResolver: string[], apis: Apis): Observable<any>{
    
    const pathFromRoot: string[] = listaSegmenteResolver;

    const formatIcoHeader: SolicitareIco[] = [
      {
        w: 128,
        h: 128
      },
      {
        w: 64,
        h: 64
      },
    ];
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 64,
        h: 64
      }
    ];
    const solicitareArhiva: SolicitareArhiva = {
      pathFromRoot,
      formatIcoHeader,
      formatIcoContinut
    };
    // console.log('#1 RESOLVER', solicitareArhiva);
    return apis.arhiva.CitesteArhiva(solicitareArhiva);
    // .pipe(map((e: ViewArhiva) => new CompozitResolverUniversalArhiva(listaSegmenteResolver, e)));
  }

  ngOnInit(): void {
    this.route.data.subscribe((rezultat: any) => {
      if (rezultat && rezultat.hasOwnProperty('info')) {
        //console.log('#2 ONINIT', rezultat.info);
        this.viewArhiva = {...rezultat.info};
        this.segmentUrl = [...rezultat.info.folderListat];
      };
    });
  }

  editMode() {
    this.foldere = [...this.viewArhiva.foldere];
    this.modEditare = !this.modEditare;
  }

  editModeOff() {
    this.foldere = [];
    this.modEditare = false;
  }

  saveEditMode() {
    const params: SolicitareReordonare = {
      iduriProdusDeOrdonat: this.foldere.map(p => p.idProdus)
    };
    this.arhivaService.ReordoneazaProduse(params).subscribe(
      _ => {
        this.viewArhiva.foldere = this.foldere;
        this.modEditare = false;
      },
      _ => this.modEditare = false
    );
  }

  ngAfterViewInit(): void {
    this.setScroll();
  };

  setScroll() {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.log(err)
    }
  }

  dialogModificaFolderRoot() {
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 124,
        h: 124
      }
    ]
    const solicitareDetaliiProdus: SolicitareDetaliiProdus = {
      idProdus: this.viewArhiva.idProdus,
      formatIco: formatIcoContinut
    }
    this.arhivaService.DetaliiProdus(solicitareDetaliiProdus).subscribe((detaliiProdus: DetaliiProdus) => {
      console.log('detaliiProdus', detaliiProdus);

      const dlgConfig = new MatDialogConfig();
      dlgConfig.data = {
        detaliiProdus: detaliiProdus,
      }

      this.ngOnInit();
      this.matDialog.open(DialogModificaFolderComponent, {
        data: dlgConfig.data,
        autoFocus: false,
        width: '700px'
      }).afterClosed().subscribe((text: string) => {
        if (text === 'modificat') {
          this.router.navigateByUrl('/');
        }
      })
    });

  }

  ViewArhivaFoldere() {
    console.log('this.viewArhiva.foldere', this.viewArhiva.foldere); 
  }

  incarcaFisier(fileInputEvent: any) {
    // Trebuie sa se faca Merge pe master pentru a genera serviciile si interfetele
    this.procesDeIncarcare = true;

    console.log('fileInputEvent', fileInputEvent);

    const file = fileInputEvent.target.files[0] as File;
      
    const params: StreamService.IncarcaFisierParams = {
      fisier: file,
      milisecundeLastUpdated: file.lastModified,
      idProdus: 10,
      descriereFisier: "descriere fisier",
    }

    this.streamService.IncarcaFisier(params)
      .pipe(
        catchError(eroare => {
          throw new Error(eroare);
        }),
        finalize(() => {
          this.procesDeIncarcare = false;
        })
      )

    .subscribe((raspuns: RaspunsTestIncarcareFisier) => {
      if (raspuns) {
        this.openSnackBar('Fisierul a fost incarcat cu succes', '');
      } else {
        this.openSnackBar('A intervenit o eroare la incarcarea fisierului', '');
      }
      this.procesDeIncarcare = false;
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}