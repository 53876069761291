import { EmptyGUID } from './../../../boilerplate/constante.comune';
import { AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { DetaliiPanou, SolicitareListaPanouri, SumarPanou } from 'src/app/backendapi/models';
import { Apis } from 'src/app/backendapi/resolver-universal';
import { CfgPanouService } from 'src/app/backendapi/services';
import { UserService } from 'src/app/services/user.service';
import { DialogUniversalComponent } from 'src/app/utils/dialogs/dialog-universal/dialog-universal.component';

@Component({
  selector: 'app-panouri',
  templateUrl: './panouri.component.html',
  styleUrls: ['./panouri.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanouriComponent implements OnInit, AfterViewInit{
  
  sumarPanou: SumarPanou[] = [];
  mouseHover: number;

  displayedColumns: string[] = ['panouId', 'descriere', 'actiuni'];
  dataSource: MatTableDataSource<SumarPanou>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // meniu adauga panou
  @ViewChild('triggerMeniuAdauga') _triggerMeniuAdaugaRef: MatMenuTrigger;
  @ViewChild('focuspanouId') _focuspanouIdRef: ElementRef;
  @ViewChild('focusDescriere') _focusDescriereRef: ElementRef;
  panouId = '';
  descrierePanou = '';

  constructor(
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private panouService: CfgPanouService,
    private atentionareService: AtentionareAcsService,
    public bazaUserService: BazaUserService,
  ) { }

  public static ResolveUniversal(param: Params, queryParams: Params, apis: Apis, userService: UserService): Observable<any> {
    const solicitareListaPanouri: SolicitareListaPanouri = {
      formatIco: [
        {
          "w": 64,
          "h": 64
        }
      ]
    };
    return apis.cfgPanou.ListaPanouriDefinite(solicitareListaPanouri);
  }

  ngOnInit(): void {
    this.route.data.subscribe((rezultat: any) => {
      this.sumarPanou = rezultat.info as SumarPanou[];
      console.log('### this.sumarPanou', this.sumarPanou);
      this.dataSource = new MatTableDataSource<SumarPanou>(this.sumarPanou);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort;
  }

  adaugaPanou() {
    const detaliiPanouNou: DetaliiPanou = {
      panouId: this.panouId,
      // panouIdAnterior: this.panouId,
      descriere: this.descrierePanou,
      // imagineBase64:
      // urlImagine:
      elementeInPanou: [],
      administratori: [],
    };

    // Adaug efectiv panoul nou
    this.panouService.AdaugaSauModificaPanou(detaliiPanouNou).subscribe(() => {
      console.log('Am adaugat panoul cu succes');
      const solicitareListaPanouri: SolicitareListaPanouri = {
        formatIco: [
          {
            "w": 64,
            "h": 64
          }
        ]
      };
      this.panouService.ListaPanouriDefinite(solicitareListaPanouri).subscribe((e: SumarPanou[]) => {
        console.log('e', e);
        this.sumarPanou = e;
        this.dataSource = new MatTableDataSource<SumarPanou>(this.sumarPanou);
        this.panouId = '';
        this.descrierePanou = '';
        this._triggerMeniuAdaugaRef.closeMenu();
      });
    })

  }

  stergePanou(sumarPanou: SumarPanou) {
    const dlgConfig = new MatDialogConfig();
    dlgConfig.data = {
      header_text: "Confirmare stergere Panou",
      text: "Sigur doriti sa stergeti panoul: ",
      nume: sumarPanou.descriere + '?'
    };

    this.matDialog.open(DialogUniversalComponent, {
      data: dlgConfig,
      autoFocus: false,
      panelClass: 'dialog-class-detalii-parinte',
    }).afterClosed().subscribe(rezultat => {
      if (!!rezultat) {
        this.panouService.StergePanou(sumarPanou.panouId).subscribe(
          _ => {
            const index = this.sumarPanou.findIndex(p => p.panouId == sumarPanou.panouId);
            if (index >= 0) {
              this.sumarPanou.splice(index, 1);
              this.dataSource = new MatTableDataSource<SumarPanou>(this.sumarPanou);
            } else {
              console.log('Nu exista panoul cu valoarea property-ului panouId: ', sumarPanou.panouId, ' in lista de panouri!')
            }
          },
          (err) => {
            this.atentionareService.Eroare(err);
          });
      }
    })
  }

  focusPanouId() {
    setTimeout(() => {
      this._focuspanouIdRef.nativeElement.focus();
    }, 1);
  }

  focusDescriere() {
    setTimeout(() => {
      this._focusDescriereRef.nativeElement.focus();
    }, 1);
  }

  resetMeniuAdaugaPanou(idPanou, descrierePanou) {
    idPanou.reset();
    descrierePanou.reset();
  }
}
