import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/backendapi/services';
import { MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionType } from "@azure/msal-browser";
import { UserService } from '../services/user.service';
import { BazaUserService } from '@acs/infrastructura-standard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-necesita-ms-login',
  templateUrl: './necesita-ms-login.component.html',
  styleUrls: ['./necesita-ms-login.component.scss'],
})
export class NecesitaMsLoginComponent implements OnInit {

  constructor(
    private authService: AuthService, 
    private mAuth: MsalService,
    public userService: UserService,
    private bazaUserService: BazaUserService,
  ) { }

  ngOnInit(): void {
  }

  public Proba(): void {
    this.authService.SPing('test').subscribe(msg => {
      console.log('S-a raspuns', msg);
    })
  }

  LoginFolosindMicrosoftAccount() {
    const scopes = ["user.read"];

    // Sets account as active account or first account
    let account: AccountInfo;
    if (!!this.mAuth.instance.getActiveAccount()) {
      this.mAuth.getLogger().verbose("Interceptor - active account selected");
      account = this.mAuth.instance.getActiveAccount();
    } else {
      this.mAuth.getLogger().verbose("Interceptor - no active account, fallback to first account");
      account = this.mAuth.instance.getAllAccounts()[0];
    }

    this.mAuth.acquireTokenPopup({...this.MSALInterceptorConfigFactory().authRequest, scopes, account}).subscribe(x => {
      this.authService.LoginFolosindMicrosoft(x.idToken).subscribe(jwt => {
        if (!!jwt)
        {
          console.log('Login cu succes', jwt);
          if (jwt[0] === '"' || jwt[0] === "'")
          {
            jwt = jwt.substring(1, jwt.length-1)
            console.log('Am redus jwt la: ', jwt)
          }
          localStorage.removeItem(environment.NumeToken);
          this.bazaUserService.Token = jwt;
          this.bazaUserService.NavigheazaLaHomepage();
        }
      })
    })
  }

  private MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.micrqosoft.com/v1.0/me', ['user.read']);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }

}
