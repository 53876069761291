<div class="container_dialog">
  <h1 mat-dialog-title>
    <div fxLayout="column">
      <div 
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="text-header-dialog"
      >
        <div>
          <p>{{utilizatorId.value === EmptyGUID ? 'Adaugă Utilizator' : 'Modifică Utilizator'}}</p>
        </div>
        <div class="close-button">
          <button mat-icon-button [mat-dialog-close]="false">
            <mat-icon class="color-gri">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </h1>
  
  <form [formGroup]="detaliiUtilizatorForm">
    <div fxLayout="column" fxLayoutGap="10px">

      <div 
        fxLayout="row"
        fxFlex
        fxLayoutGap="25px"
        fxLayoutGap.lt-sm="0px"
        fxLayout.lt-sm="column"
      >
        <mat-form-field fxFlex>
          <input
            type="text"
            matInput
            placeholder="Nume" 
            id="nume"
            name="nume"
            [readonly]='false'
            [formControlName]="'nume'" 
            #numeForm
          >
          <!-- <mat-hint>*Identificatorul bazei de date, trebuie să fie un cuvânt simplu, format din litere și cifre, fără spații</mat-hint> -->
          <mat-error>*Acest camp este obligatoriu</mat-error>
        </mat-form-field>
      </div>
  
      <div 
        fxLayout="row"
        fxFlex
        fxLayoutGap="25px"
        fxLayoutGap.lt-sm="10px"
        fxLayout.lt-sm="column"
      >

        <mat-form-field fxFlex>
          <input  
            type="text"
            matInput
            placeholder="E-mail" 
            id="email"
            name="email"
            [formControlName]="'email'" 
            required
          >
          <mat-icon matSuffix class="color-gri">email</mat-icon>

          <!-- <mat-hint>*Denumirea bazei de date, așa cum va fi afișată în ecranul de Login.</mat-hint> -->
          <mat-error>*Acest camp este obligatoriu</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex>
          <input
            type="text"
            matInput
            placeholder="Telefon" 
            id="telefon"
            name="telefon"
            [readonly]='false'
            [formControlName]="'telefon'" 
            required
            #telefonForm
          >
          <mat-icon matSuffix class="color-gri">call</mat-icon>

          <!-- <mat-hint>*Identificatorul bazei de date, trebuie să fie un cuvânt simplu, format din litere și cifre, fără spații</mat-hint> -->
          <mat-error>*Acest camp este obligatoriu</mat-error>
        </mat-form-field>

      </div>

      <br>
    
      <mat-checkbox 
        [checked]="utilizatorulPoateIncarcaKitDeInstalareAutomat"
        (change)="solicitCheieDacaNuAm();"
        [indeterminate]="false"
        color="primary"
      >
        <p style="white-space: pre-line;">Utilizatorul poate incarca KIT-uri de instalare in mod automat</p>
      </mat-checkbox>
    </div>

    <div 
      *ngIf="utilizatorulPoateIncarcaKitDeInstalareAutomat || requestCheie" 
      fxLayout="column" 
      fxLayoutGap="5px"
    >
      <br>

      <div>
        <button 
          *ngIf="utilizatorulPoateIncarcaKitDeInstalareAutomat"
          mat-icon-button 
          style="margin-bottom: 7px;"
          (click)="
             cheieDeAutorizare; 
            openSnackBar('Cheia a fost salvata in clipboard.', ''); 
            $event.stopPropagation();
           " 
        >
          <mat-icon 
            matPrefix
            [cdkCopyToClipboard]="cheieDeAutorizare.value"
            class="color-gri"
            style="font-size: 17px;"
          >content_copy</mat-icon>
        </button>
        <mat-form-field fxFlex>
          <input  
            type="text"
            matInput
            placeholder="Cheie de autorizare" 
            id="cheieDeAutorizare"
            name="cheieDeAutorizare"
            [formControlName]="'cheieDeAutorizare'"
          >
  
          <mat-label *ngIf="!utilizatorulPoateIncarcaKitDeInstalareAutomat && requestCheie">
            <div class="select-placeholder-container" fxLayout="row" fxLayoutAlign="space-between center">
              <span>Se incarca...</span>
              <mat-spinner class="spinner" diameter="20"></mat-spinner>
            </div>
          </mat-label>
  
          <span *ngIf="utilizatorulPoateIncarcaKitDeInstalareAutomat" matSuffix><mat-icon class="color-gri" style="font-size: 17px;">password</mat-icon></span>
        </mat-form-field>
      </div>

      <mat-form-field fxFlex>
        <input  
          type="text"
          matInput
          placeholder="Numele utilizatorului in arhiva" 
          id="numeleUtilizatoruluiInArhiva"
          name="numeleUtilizatoruluiInArhiva"
          [formControlName]="'numeleUtilizatoruluiInArhiva'"
          #numeleUtilizatoruluiInArhiva
        >
      </mat-form-field>

    </div>

  </form>

  <br>

  <p style="margin: 10px 0 10px 0;">Flaguri securitate posibile:</p>

  <div fxLayout="row" fxLayoutGap="25px">
    <mat-checkbox
      *ngFor="let e of flaguriSecuritatePosibile"
      color="primary"
      [checked]="flaguriSecuritate.includes(+e.valoare)"
      (change)="checkboxChange(e.valoare, $event.checked);"
    >
      <p>{{e.text}}</p>
    </mat-checkbox>
  </div>

  <br>

  <mat-dialog-actions fxLayoutr="row" style="padding: 10px 0 25px 0;">
    <button 
      fxFlex 
      mat-button 
      mat-dialog-close
      class="font_weight200"
    >Renunţă</button>

    <button
      fxFlex
      mat-stroked-button
      class="font_weight200"
      [disabled]="detaliiUtilizatorForm.status === 'INVALID'"
      (click)="salveazaModifica()"
    >
      Continuă
    </button>
  </mat-dialog-actions>
</div>
