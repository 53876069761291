import { RefFisierInArhiva } from './../../../backendapi/models/ref-fisier-in-arhiva';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RefFolderInArhiva } from 'src/app/backendapi/models';

@Component({
  selector: 'app-fisier-editare-panou',
  templateUrl: './fisier-editare-panou.component.html',
  styleUrls: ['./fisier-editare-panou.component.scss']   
})
export class FisierEditarePanouComponent implements OnInit {

  @Output() alegeFisier = new EventEmitter<RefFisierInArhiva>();
  @Input() fisier: RefFisierInArhiva;
  @Input() tipDeSelect: string;
  hovered = false;
  @Input() imagine: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
