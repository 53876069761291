<h1 mat-dialog-title style="margin-bottom: -7px !important">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div fxFlex style="margin: 7px 25px 0 0;">
        <p>Detalii Produs {{data.detaliiProdus.denumireProdus}}</p>
      </div>
      
      <div class="close-button">
        <button
          mat-icon-button
          [mat-dialog-close]="false"
        >
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</h1>

<br>

<mat-dialog-content>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center" fxLayoutAlign="space-between end" fxLayoutGap="5px">
    <div 
      fxLayout="row" 
      fxLayoutAlign="start"
      fxLayout.lt-sm="column"
      fxLayoutGap="10px"
      fxLayoutGap.lt-sm="0px"
      fxFlex
    >
      <mat-form-field 
        fxFlex
        class="input"
        fxFlex.lt-sm
        fxFlex.sm
      >
        <mat-label>Denumire produs</mat-label>
        <input
          matInput
          [(ngModel)]="detaliiProdus.denumireProdus"
          #denumireProdus="ngModel"
          type="text"
          required
        >
        <mat-error>Introduceţi o valoare</mat-error>
      </mat-form-field>
    </div>

    <br>
    
    <!-- linkImaginiHeader -->
    <div fxLayout="column" fxLayoutAlign="center center">
      <ng-container *ngIf="imagineHeaderInvalida">
        <p style="color: red; font-size: 12px;">
          <span style="position: relative; left: 7px;">Imagine invalidă</span> 
          <mat-icon 
            style="color: rgb(255, 94, 94); position: relative; bottom: -16px; left: 7px; z-index: 9; cursor: help;"
            [matTooltip]="'Format imagine suportat: jpe/g, tiff, png, webp, bmp, ico.'"
          >help</mat-icon>
        </p>
      </ng-container>

      <div [class.borderEroareImagine]="imagineHeaderInvalida">
        <input
          type="file"
          name="image"
          id="targetInputFileHeader"
          (change)="onFileChanged($event, 'imaginiHeader')"
          style="display: none;"
        >
        <label for="targetInputFileHeader">
          <!-- Se foloseste imaginea din property-ul "linkImaginiHeader" daca exista -->
          <ng-container *ngIf="!!detaliiProdus.linkImaginiHeader">
            <ng-container *ngIf="detaliiProdus.linkImaginiHeader[0].startsWith('https://')">
              <img 
                src="{{detaliiProdus.modificaImagineBase64 ? ('data:image/png;base64,' + detaliiProdus.modificaImagineBase64) : detaliiProdus.linkImaginiHeader[0]}}"
                alt="imagine-viewArhiva-https"
                class="modifica_ico"
              >
            </ng-container>
          </ng-container>

          <p 
            fxLayout="row" 
            fxLayoutAlign="center center" 
            (change)="onFileChanged($event, 'imaginiHeader')"
            class="text_schimba_imaginea"
          >Imagine Header</p>
        </label>

      </div>

    </div>

    <!-- linkImagineSetup -->
    <div fxLayout="column" fxLayoutAlign="center center">
      <ng-container *ngIf="imagineSetupInvalida">
        <p style="color: red; font-size: 12px;">
          <span style="position: relative; left: 7px;">Imagine invalidă</span> 
          <mat-icon
            style="color: rgb(255, 94, 94); position: relative; bottom: -16px; left: 7px; z-index: 9; cursor: help;"
            [matTooltip]="'Format imagine suportat: jpe/g, tiff, png, webp, bmp, ico.'"
          >help</mat-icon>
        </p>
      </ng-container>

      <div [class.borderEroareImagine]="imagineSetupInvalida">
        <input 
          type="file"
          name="image"
          id="targetInputFileSetup"
          (change)="onFileChanged($event, 'imagineSetup')"
          style="display: none;"
        >
        <label for="targetInputFileSetup">
          <ng-container *ngIf="!!detaliiProdus.linkImagineSetup;">
            <img
              src="{{detaliiProdus.modificaImagineSetupBase64 ? ('data:image/png;base64,' + detaliiProdus.modificaImagineSetupBase64) : detaliiProdus.linkImagineSetup[0]}}"
              alt="imagine-viewArhiva-https"
              class="modifica_ico"
            >
          </ng-container>
          
          <p
            fxLayout="row" 
            fxLayoutAlign="center center" 
            (change)="onFileChanged($event, 'imagineSetup')"
            class="text_schimba_imaginea"
          >Imagine setup</p>
        </label>

      </div>

    </div>

  </div>
  
  <br>

  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
      <div fxFlex="72">
        <mat-form-field style="width: 100%;" >
          <mat-label>Descriere produs in antetul paginii atunci cand afisez {{detaliiProdus.folderSiteCandCompunUrl}}</mat-label>
          <p style="line-height: 1 !important;">
            <textarea 
              matInput 
              #descriereProdusCandListezProdusul="ngModel"
              #denumireFocus
              matTextareaAutosize
              [matAutosizeMinRows]="1"
              [matAutosizeMaxRows]="4"
              required
              type="text"
              name="descriereProdusCandListezProdusul"
              id="descriereProdusCandListezProdusul"
              [(ngModel)]="detaliiProdus.descriereProdusCandListezProdusul"
            ></textarea>
          </p>
          <mat-error>
            <p>Acest câmp este obligatoriu!</p>
          </mat-error>
        </mat-form-field>  
      </div>

      <mat-form-field 
        fxFlex
        fxFlex.lt-sm
        fxFlex.sm
      >
        <mat-label>Folder site Url</mat-label>
        <input
          matInput
          [(ngModel)]="detaliiProdus.folderSiteCandCompunUrl"
          #folderSiteCandCompunUrl="ngModel"
          type="text"
          required
        >
        <mat-error>Introduceţi o valoare</mat-error>
      </mat-form-field>
    </div>

    <br>
          
    <mat-form-field style="width: 100%">
      <!-- testModifica -->
      <mat-label class="testModifica">Descriere in lista produsului parinte</mat-label>
        <p>
          <textarea 
            matInput 
            #descriereInListaProdusuluiParinte="ngModel"
            #denumireFocus
            matTextareaAutosize
            [matAutosizeMinRows]="1"
            [matAutosizeMaxRows]="4"
            required
            type="text"
            class="input"
            name="descriereInListaProdusuluiParinte"
            id="descriereInListaProdusuluiParinte"
            [(ngModel)]="detaliiProdus.descriereInListaProdusuluiParinte"
          ></textarea>
        </p>
        <mat-error>
          <p>Acest câmp este obligatoriu!</p>
        </mat-error>
    </mat-form-field> 

    <div class="policy">

      <div 
        fxLayout="column" 
        fxLayoutAlign="start start" 
        fxLayoutGap="15px"
        fxLayoutAlign.lt-md="center center"
      >
        <mat-checkbox
          color="primary"
          [checked]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte"
          #retPolicyBadRelease="ngModel"
          [(ngModel)]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte"
        >
          <!-- [disabled]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte && detaliiProdus.retPolicyBeta.mosteniteDeLaParinte" -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <p>retPolicyBadRelease</p>
          </div>
        </mat-checkbox>
        
        <div 
          style="
            display: inline-flex; 
            width: 306px;
            justify-content: space-around !important;
          "
        >
          <mat-form-field
            class="input"
            style="width: 45%"
          >
            <mat-label>Nr. minim de zile</mat-label>
            <input
              matInput
              [(ngModel)]="detaliiProdus.retPolicyBadRelease.minNrZile"
              type="number"
              [disabled]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte && detaliiProdus.retPolicyBeta.mosteniteDeLaParinte"
            >
          </mat-form-field>
    
          <mat-form-field 
            class="input"
            style="width: 45%"
          >
            <mat-label>Nr. minim de fisiere</mat-label>
            <input
              matInput
              [(ngModel)]="detaliiProdus.retPolicyBadRelease.minNumarDeFisiere"
              type="number"
              [disabled]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte && detaliiProdus.retPolicyBeta.mosteniteDeLaParinte"
            >
          </mat-form-field>
        </div>
      </div>
  
      <div 
        fxLayout="column" 
        fxLayoutAlign="start start" 
        fxLayoutGap="15px"
        fxLayoutAlign.lt-md="center center"
      >
        <mat-checkbox
          color="primary"
          [checked]="detaliiProdus.retPolicyBeta.mosteniteDeLaParinte"
          #retPolicyBeta="ngModel"
          [(ngModel)]="detaliiProdus.retPolicyBeta.mosteniteDeLaParinte"
          >
          <!-- [disabled]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte && detaliiProdus.retPolicyBeta.mosteniteDeLaParinte" -->
          <div fxLayout="row" fxLayoutAli gn="start center">
            <p>retPolicyBeta</p>
          </div>
        </mat-checkbox>
        
        <!-- style="width: 260px !important" -->
        <div style="display: inline-flex; width: 298px; justify-content: space-around;">

          <mat-form-field
            class="input"
            style="width: 45%"
          >
            <mat-label>Nr. minim de zile</mat-label>
            <input
              matInput
              [(ngModel)]="detaliiProdus.retPolicyBeta.minNrZile"
              type="number"
              [disabled]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte && detaliiProdus.retPolicyBeta.mosteniteDeLaParinte"
            >
          </mat-form-field>
    
          <mat-form-field 
            class="input"
            style="width: 45%"
          >
            <mat-label>Nr. minim de fisiere</mat-label>
            <input
              matInput
              [(ngModel)]="detaliiProdus.retPolicyBeta.minNumarDeFisiere"
              type="number"
              [disabled]="detaliiProdus.retPolicyBadRelease.mosteniteDeLaParinte && detaliiProdus.retPolicyBeta.mosteniteDeLaParinte"
            >
          </mat-form-field>
        </div>
      </div>

    </div>

    <br>

    <ng-container *ngIf="!!detaliiProdus.detaliiAcl">
      <mat-tab-group 
        animationDuration="0ms"
        #matTab1Group
        [selectedIndex]="0"
        fxLayout="row"
      >
        <!-- (selectedIndexChange)="setModificaTab1($event);" -->
        
        <ng-container *ngFor="let detaliiAcl of detaliiProdus.detaliiAcl; let i = index">
          <mat-tab>

            <div fxLayout="row" style="margin: 0 5px;">

              <ng-template mat-tab-label>
                <div fxLayout="column">
                  {{detaliiAcl.numeTab}}
                </div>
              </ng-template>
              
              <div style="min-width: 370px !important; max-width: 384px !important; margin-right: 10px;">
                
                <div fxLayout="row" style=" margin: 10px 0 5px 0;">
                  <p style="width: 100% !important">{{detaliiAcl.descriere}}</p>
                </div>

                <br>

                <ng-container *ngIf="!!detaliiAcl.utilizatoriAcl">
                  <ng-container *ngFor="let utilizatorAcl of detaliiAcl.utilizatoriAcl; let j = index">

                    <p style="font-weight: 400;">{{utilizatorAcl.denumire}}</p>

                    <ng-container *ngIf="!!utilizatorAcl.posibilitati">
                      <div id="test">
                        <mat-form-field id="test">
                          <mat-select [(value)]="utilizatorAcl.selectat" (selectionChange)="detaliiAclModificat = true" style="white-space: pre-wrap;">
                            <mat-option *ngFor="let posibilitate of utilizatorAcl.posibilitati; let k = index" [value]="posibilitate.valoare">
                              <p style="white-space: pre-wrap; line-height: 1;">{{posibilitate.descriere}}</p>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </ng-container>
  
                    <br>
                    
                  </ng-container>
                </ng-container>
                
              </div>

            </div>

            <div fxLayout="row" fxLayoutAlign="end center" style="margin-right: 15px;">
              <button 
                mat-mini-fab
                (click)="adaugaUtilizator(detaliiAcl)" 
                fxLayoutAlign="center center"
                [color]="color"
                class="mat-elevation-z2"
                style="margin: 3px;"
                (mouseover)="color = 'primary'"
                (mouseout)="color = 'secondary'"
              >
                <mat-icon 
                  [ngStyle]="{'color': (color === 'secondary') ? 'gray' : 'white',
                    'font-size': '19px',
                    'margin-top': '6px'
                  }"
                >
                  person_add_outline
                </mat-icon>

              </button>
            </div>

          </mat-tab>

        </ng-container>

      </mat-tab-group>

    </ng-container>
  </div>
</mat-dialog-content>

<div fxLayout="column">
  <mat-dialog-actions fxLayout="row" style="margin-top: 10px;">
    <button 
      fxFlex 
      mat-button 
      class="buton-renunt"
      mat-dialog-close
    ><p>Renunţă</p></button>
      
    <button
      fxFlex
      mat-stroked-button
      class="buton-continua"
      (click)="salveaza()"
      [disabled]="validareCampuri(
        denumireProdus, 
        descriereInListaProdusuluiParinte, 
        descriereProdusCandListezProdusul, 
        folderSiteCandCompunUrl, 
        retPolicyBadRelease, 
        retPolicyBeta,
        detaliiAclModificat
      )"
    >
      <p>Salvează</p>
    </button>
  </mat-dialog-actions>
  <br>
</div>
