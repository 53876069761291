import { Router, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Injectable } from "@angular/core";
import { NumeToken, Rol } from './constante.comune';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private previousUrls: string[] = [];
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      // daca cel pe care vreau sa il adaug acum este cel care a fost 1 tura inainte, nu mai adaug nimic, ci sterg ultima inregistrare
      if (event instanceof NavigationEnd) {
        if (this.previousUrl === event.url) {
          this.previousUrls.splice(this.previousUrls.length - 1, 1);
          this.previousUrl = this.previousUrls[this.previousUrls.length - 1];
          this.currentUrl = event.url;
        } else {
          this.previousUrls.push(this.currentUrl);
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      };
    });
  }

  navigateBack() {
    if (!this.previousUrl || this.previousUrl === "/") {
      if (!!this.currentUrl) {
        const routerLinkArray = this.currentUrl.split("/").map(p => "/" + p);
        routerLinkArray.splice(0, 1);
        routerLinkArray.splice(routerLinkArray.length - 1, 1);
        this.router.navigate(routerLinkArray);
      } else {
        this.router.navigate(["/"]);
      }
    } else {
      this.router.navigate([this.previousUrl]);
    }
  }

  parseJwt(token) {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  }

  CalculeazaCoordonatePentruPopup() {
    const latime = (80 * window.screen.width) / 100;
    const inaltime = (80 * window.screen.height) / 100;
    const top = (window.screen.height - inaltime) / 2;
    const left = (window.screen.width - latime) / 2;
    return `width=${latime},height=${inaltime},top=${top},left=${left}`;
  }

  getNextTempId(listaIduri: string[]) {
    let nextId = 1;
    if (!!listaIduri) {
      const iduriTemporare = listaIduri.filter(p => p.includes('tempId')).map(p => +(p.replace('tempId', '')));
      nextId = iduriTemporare.length > 0 ? iduriTemporare.reduce((a, b) => Math.max(a, b)) + 1 : 1;
    }
    return 'tempId' + nextId;
  }

  getIdUserLogat() {
    const decodedToken = this.parseJwt(localStorage.getItem(NumeToken));
    return !!decodedToken ? decodedToken.sub : '';
  }

  getUserRole() {
    const decodedToken = this.parseJwt(localStorage.getItem(NumeToken));
    return !!decodedToken ? decodedToken.rol : '';
  }

  esteUserAutentificat() {
    return !!localStorage.getItem(NumeToken);
  }

  esteAdministrator() {
    return this.getUserRole() === Rol.Administrator;
  }

  primaLiteraMare(inputString: string) {
    if (!inputString || inputString.length === 0) {
      return inputString;
    } else {
      return inputString.charAt(0).toUpperCase() + inputString.substr(1).toLowerCase();
    }
  }

  dateleSuntEgale(data1: string, data2: string) {
    return this.datePipe.transform(data1, 'yyyy-MM-dd').valueOf() === this.datePipe.transform(data2, 'yyyy-MM-dd').valueOf();
  }

  dateleDateSuntEgale(data1: Date, data2: Date) {
    return data1.valueOf() === data2.valueOf();
  }
  
  dateleFaraTimpSuntEgale(data1: Date, data2: Date) {
    const d1 = new Date(data1.getFullYear(), data1.getMonth(), data1.getDate());
    const d2 = new Date(data2.getFullYear(), data2.getMonth(), data2.getDate());
    return d1.valueOf() === d2.valueOf();
  }

  copiazaInObiectNou(obiect: any) {
    return JSON.parse(JSON.stringify(obiect));
  }

  numarZileInLuna(an: number, luna: number){
    return new Date(an, luna + 1, 0).getDate();
  }

  numarZileIntreDateleInclusiv(data1: Date, data2: Date){
    const difTimp = Math.abs(data1.valueOf()-data2.valueOf());
    const difZile = Math.ceil(difTimp / (1000 * 60 * 60 * 24)); 
    return difZile;
  }
}
