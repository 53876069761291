import { Injectable } from '@angular/core';
import { finalize, map, catchError } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Import serviciile API
import { ArhivaService } from './services/arhiva.service';
import { AuthService } from './services/auth.service';
import { CfgPanouService } from './services/cfg-panou.service';
import { FisierService } from './services/fisier.service';
import { StreamService } from './services/stream.service';
import { UtilizatoriService } from './services/utilizatori.service';
import { Router } from '@angular/router';
import { AtentionareAcsService } from '@acs/infrastructura-standard';

@Injectable()
export class Apis {
    constructor(
        public arhiva: ArhivaService,
        public auth: AuthService,
        public cfgPanou: CfgPanouService,
        public fisier: FisierService,
        public stream: StreamService,
        public utilizatori: UtilizatoriService,
        public router: Router,
        public atentionari: AtentionareAcsService,
    ) {}
}

@Injectable()
export class ResolverUniversal implements Resolve<any> {

    constructor(private apis: Apis) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        if (route.component) {
            let c: any;
            c = route.component;
            if (c.ResolveUniversal) {

                // Pregatesc parametrii pentru Resolver; In principu extrag din Ruta tot ce pot
                let ru;
                try {
                    ru = c.ResolveUniversal(route.params, route.queryParams, this.apis);
                } catch (error) {
                    // S-a intamplat "ceva" cand am apelat ResolveUniversal... ce? De ce?
                    console.log(`A fost ridicata o exceptie cand am apelat ResolveUniversal. ${error.message}`);
                    this.apis.atentionari.Eroare(error.message);
                    return EMPTY;
                }

                if (ru === EMPTY) {
                    return EMPTY;
                }

                // "ru" este sau nu Observable?
                if (ru instanceof Observable) {
                    // No bun, "ru" este Observable, incerc sa citesc datele
                    this.apis.atentionari.PornesteProgressBar('resolver-universal');

                    return ru
                        .pipe(
                            finalize(() => {
                                this.apis.atentionari.OpresteProgressBar('resolver-universal');
                            }),
                            map(d => {
                                if (d) {
                                    return d;
                                }

                                this.apis.atentionari.Eroare('API nu a returnat date');
                                return EMPTY;
                            }),
                            catchError(error => {
                                this.apis.atentionari.Eroare(JSON.stringify(error));
                                return EMPTY;
                            })
                        );
                } else {
                    this.apis.atentionari.Eroare('ResolveUniversal nu a returnat un Observable');
                }
            } else {
                this.apis.atentionari.Eroare('Nu are o metoda ResolveUniversal');
                return EMPTY;
            }
        } else {
            // Nu am componenta: nu pot rezolva
            this.apis.atentionari.Eroare('Ruta nu are Componenta');
            return EMPTY;
        }
    }
}
