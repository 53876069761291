<div class="folder">
  <div fxLayout="space-between" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div 
      fxLayout="row" 
      fxLayoutAlign="start center"
    >
      <img
        [src]=folder.ico[0]
        alt="imagine-folder"
        style="z-index: 1;"
      > 

      <div fxLayout="column" style="margin-left: 10px;">
        <p style="font-weight: 400">
          {{folder.denumire}}
        </p>
        <p style="margin-right: 20px;">
          {{folder.explicatii}}
        </p>
      </div>
    </div>

    <div fxFlex></div>

    <div (click)="$event.stopPropagation();">
      <ng-container *ngIf="tipDeSelect === 'produs'">
        <button
          mat-stroked-button
          class="buton_alege"
          (click)="$event.stopPropagation(); determinamDacaFolderulAreFisier.emit()"
        >
          Alege
        </button>
      </ng-container>
    </div>

  </div>
</div>