import { AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/backendapi/services';
import { UserService } from 'src/app/services/user.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
@Component({
  selector: 'app-dialog-schimba-reset-parola',
  templateUrl: './dialog-schimba-reset-parola.component.html',
  styleUrls: ['./dialog-schimba-reset-parola.component.scss']
})
export class DialogSchimbaResetParolaComponent implements OnInit {


  parolaVeche: string;
  parolaNoua: string;
  confirmareParola: string;

  tipDeVizualizare_parolaVeche = false;
  tipDeVizualizare_parolaNoua = false;
  tipDeVizualizare_confirmareParola = false;

  eroare = false;

  // data: ISchimbaParola;
  utilizatorId: string;
  NumeUtilizator: string;

  userForm: FormGroup;
  @ViewChild("parolaNouaRef") _parolaNoua: ElementRef;
  @ViewChild("parolaVecheRef") _parolaVeche: ElementRef;
  errorMatcher = new CrossFieldErrorMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogConfig: MatDialogConfig,
    private dialogRef: MatDialogRef<DialogSchimbaResetParolaComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private bazaUserService: BazaUserService,
    private userService: UserService,
    private mesaj: AtentionareAcsService,
  ) {
    this.utilizatorId = dialogConfig['UtilizatorId'] as string;
    this.NumeUtilizator = dialogConfig['NumeUtilizator'] as string;
    console.log('data', dialogConfig)
    this.initForm();
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.utilizatorId) {
        this._parolaVeche.nativeElement.focus();
      } else {
        this._parolaNoua.nativeElement.focus();
      }
    }, 50)
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      parolaVeche: [''],
      parolaNoua: ['', Validators.required],
      confirmareParola: ['', Validators.required]
    }, {
      validator: this.passwordValidator,
    })
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('parolaNoua').value !== form.get('confirmareParola').value;
    return condition ? { passwordsDoNotMatch: true} : null;
  }

  SchimbaParola() {
    // parola personala
    if (!this.utilizatorId) {
      const resetParolaPersonalaParams: AuthService.ResetParolaPersonalaParams = {
        parolaVeche: this.userForm.get('parolaVeche').value,
        parolaNoua: this.userForm.get('parolaNoua').value,
      }
    
      this.authService.ResetParolaPersonalaResponse(resetParolaPersonalaParams)
        .pipe(
          catchError((err) => {
            this.eroare = true;
            this.userForm.reset();
            return of(err);
          })
        )  
        .subscribe((e) => {
          console.log('Parola Personala', e)
          this.bazaUserService.Token = e.body.jwt;
          this.dialogRef.close(true);
          this.mesaj.Succes("Noua parola a fost salvata cu succes!");
        })
    
      // parola alt utilizator
    } else {
      const resetParolaUtilizatoruluiParams: AuthService.ResetParolaUtilizatoruluiParams = {
        UtilizatorId: this.utilizatorId,
        parolaNoua: this.userForm.get('parolaNoua').value,
      }
      this.authService.ResetParolaUtilizatorului(resetParolaUtilizatoruluiParams)
        .pipe(
          catchError((err) => {
            this.eroare = true;
            this.userForm.reset();
            return of(err);
          })
        )  
        .subscribe((e) => {
          this.dialogRef.close(true);
          this.mesaj.Succes("Noua parola a utilizatorului: " + this.NumeUtilizator + " a fost modificata cu succes!");
        })

    }
  }
}
 
