export const EmptyGUID = '00000000-0000-0000-0000-000000000000';
export const NumeToken = 'acsDowAT';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export enum Rol {
  Administrator = 'Administrator',
}
