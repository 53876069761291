<app-cu-meniu>
  
  <br>

  <div class="container">
    
    <div fxLayot="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button
        *ngIf="bazaUserService.listaRoluri.includes('Admin')"
        mat-stroked-button
        [matMenuTriggerFor]="meniuAdauga"
        #triggerMeniuAdauga="matMenuTrigger"
        style="color: gray;"
        (menuOpened)="focusPanouId()"
        (menuClosed)="resetMeniuAdaugaPanou(panouIdAdaugarePanou, descrierePanouAdaugarePanou)"
      >
        <mat-icon style="color: #3F51B5; font-size: 30px; height: 30px; width: 30px;">
          add_circle
        </mat-icon>
          Adaugă
      </button>

    </div>

    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
      <ng-container matColumnDef="panouId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 50px !important; margin-right: 15px;"></th>
        <td 
          mat-cell 
          *matCellDef="let element; let i = index"
        >
          <a [routerLink]="['/panouri', element.panouId]">
            <div fxLayout="row" fxLayoutAlign="start center">
              <img
                [src]="element.urlImagini[0]"
                alt="panou-imagine"
                style="z-index: 1; margin-right: 5px; width: 30px;"
              >
              
            </div>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="descriere">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="margin-right: 15px;">Descriere</th>
        <td 
          mat-cell 
          *matCellDef="let element; let i = index"
        >
          <ng-container>
            <p>{{ element.descriere }}</p>
          </ng-container>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="actiuni">
        <th mat-header-cell *matHeaderCellDef style="width: 30px; text-align: center;">Actiuni</th>
        <td 
          mat-cell 
          *matCellDef="let element; let i = index"
          style="text-align: end"
        >
          <div fxLayout="row" fxLayoutGap="5px">
            <button mat-icon-button [routerLink]="['/frame', element.panouId]" matTooltip="Componenta in dezvoltare">
              <mat-icon style="color: gray;">list_alt</mat-icon>
            </button>
            <button *ngIf="bazaUserService.listaRoluri.includes('Admin')" mat-icon-button (click)="$event.stopPropagation(); stergePanou(element)">
              <mat-icon style="color: gray;">delete_outline</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; let i = index; columns: displayedColumns"
        (mouseover)="mouseHover = i"
        (mouseleave)="mouseHover = null"
        [ngClass]="{'hoverEffect': mouseHover === i}"
        [routerLink]="['/panouri', row.panouId]"
      ></tr>
    </table>
    
    <mat-paginator
      [pageSize]="15"
      [length]="10"
      [pageSizeOptions]="[15, 25, 50]"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>

  <mat-menu
    #meniuAdauga="matMenu"
    xPosition="before"
    backdropClass="customize"
  >
    <div fxLayout="column" fxLayoutAlign="start" fxFlex fxLayoutGap="5px">

      <mat-form-field fxFlex.lt-sm (click)="$event.stopPropagation()">
        <mat-label>Id panou</mat-label>
        <input
          matInput
          #panouIdAdaugarePanou="ngModel"
          #focuspanouId
          required
          [(ngModel)]="panouId"
          style="width: 450px;"
          (keydown.tab)="$event.stopPropagation()"
        >
        <mat-error>Introduceţi o valoare</mat-error>
      </mat-form-field>

      <mat-form-field (click)="$event.stopPropagation()">
        <mat-label class="testMo">Descriere panou</mat-label>
        <textarea 
          matInput
          #descrierePanouAdaugarePanou="ngModel"
          #focusDescriere="ngModel"
          matTextareaAutosize
          [matAutosizeMinRows]="1"
          [matAutosizeMaxRows]="4"
          [(ngModel)]="descrierePanou"
          (keydown.tab)="focusDescriere()"
          placeholder="Denumire panou"
          style="width: 450px;"
        ></textarea>
        <mat-error>Introduceţi o valoare</mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="end center" (click)="$event.stopPropagation()">
      <button
        fxFlex
        (click)="adaugaPanou();"
        mat-stroked-button
        [disabled]="panouIdAdaugarePanou.invalid || descrierePanouAdaugarePanou.invalid"
        style="margin-bottom: 10px;"
      >Continuă</button>
    </div>

  </mat-menu>

</app-cu-meniu>