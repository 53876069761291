import { Injectable } from "@angular/core";
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { EMPTY, Observable, of } from "rxjs";
import { Apis } from "../backendapi/resolver-universal";


@Injectable()
export class ResolverUniversalArhiva implements Resolve<any> {

    constructor(public router: Router, route: ActivatedRoute, private apis: Apis) {}

    resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        
        if (router.component) {
            let c: any = router.component;
            if (c.ResolverUniversalArhiva) {
                try {
                    let listaSegmente = state['url']
                        .split("/")
                        .filter(e => e.length > 0)
                        .filter(e => (e !== 'arhiva') && (e !== 'editare-panou'))

                    return c.ResolverUniversalArhiva(listaSegmente, this.apis);
                } catch (error) {
                    // S-a intamplat "ceva" cand am apelat ResolveUniversal... ce? De ce?
                    console.log(`A fost ridicata o exceptie cand am apelat ResolveUniversal. ${error.message}`);
                    return EMPTY;
                }
            } 
        } 
    }
}
