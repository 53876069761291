import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-cheie-upload',
  templateUrl: './dialog-cheie-upload.component.html',
  styleUrls: ['./dialog-cheie-upload.component.scss']
})
export class DialogCheieUploadComponent implements OnInit {

  cheie: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCheieUploadComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.cheie = data.cheie;
  }
  
  ngOnInit(): void {
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  salveaza() {
    this.dialogRef.close(true);
  }

}
