import { AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ArhivaService, AuthService } from '../backendapi/services';
import { JwtHelperService } from '@auth0/angular-jwt'
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../services/user.service';
import { setupTestingRouter } from '@angular/router/testing';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-buton-autentificare',
  templateUrl: './buton-autentificare.component.html',
  styleUrls: ['./buton-autentificare.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButonAutentificareComponent implements OnInit, OnDestroy {

  form: FormGroup;
  private subscription: Subscription;
  @ViewChild('username') _usernameRef: ElementRef;
  currentLocation: string = document.location.pathname;
  
  constructor(
    public userService: UserService,
    public bazaUserService: BazaUserService,
    private mesaj: AtentionareAcsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public msalService: MsalService,
    private matDialog: MatDialog,
    private arhivaService: ArhivaService
  ) { 
    this.form = this.formBuilder.group({
      usernameAuth: ['', [Validators.nullValidator]],
      passwordAuth: ['', [Validators.nullValidator]],
    });
    this.subscription = this.bazaUserService.ExistaJwtValid$.subscribe(e => {
      this.ngOnInit();
    });
  }

  get usernameAuth() {
    return this.form.get('usernameAuth');
  }
  get passwordAuth() {
    return this.form.get('passwordAuth');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  autentificare(){
    if (this.form.valid) {
      
      this.authService.Login({
        username: this.usernameAuth.value,
        password: this.passwordAuth.value
      }).subscribe(raspuns => {
        
        if (!!raspuns.jwt) {
          const helper = new JwtHelperService();

          console.log('jwt', helper.decodeToken(raspuns.jwt));
          this.bazaUserService.Token = raspuns.jwt;
          this.mesaj.Succes("Logare cu succes!");

          this.router.navigate([this.currentLocation]);
        } else {
          // pot avea eroare de user
          if (raspuns.resetUser) {
            this.usernameAuth.setValue('');
          } 
          // pot avea eroare de parola
          if (raspuns.resetPassword) {
            this.passwordAuth.setValue('');
          }

          // afisez mesajul/mesajele de eroare primite de la backend
          let mesajEroare = '';
          if (!!raspuns.eroare) {
            mesajEroare = raspuns.eroare + '; \n';
          }
          mesajEroare += raspuns.eroare_user;

          this.eroareAutentificare();

          this.mesaj.Eroare(mesajEroare);
        }
      });
    }
  }

  eroareAutentificare() {
    this.setFocus();
    this.form.reset();
  }

  setFocus() {
    setTimeout(() => {
      this._usernameRef.nativeElement.focus()
    }, 1);
  }
}
