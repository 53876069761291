import { RefFolderInArhiva } from '../../../backendapi/models/ref-folder-in-arhiva';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SolicitareIco, SolicitareDetaliiProdus, DetaliiProdus } from 'src/app/backendapi/models';
import { ArhivaService } from 'src/app/backendapi/services';
import { BazaUserService } from '@acs/infrastructura-standard';
import { DialogModificaFolderComponent } from '../../dialog/dialog-modifica-folder/dialog-modifica-folder.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-arhiva-folder',
  templateUrl: './arhiva-folder.component.html',
  styleUrls: ['./arhiva-folder.component.scss']
})
export class ArhivaFolderComponent implements OnInit {
  
  location = document.location.pathname;
  @Input() folder: RefFolderInArhiva = {};
  @Input() imagine: string = '';
  hovered = false;
  @Input() okPunLaLocBIfaBeta: boolean;
  
  constructor(
    private matDialog: MatDialog,
    private arhivaService: ArhivaService,
    public bazaUserService: BazaUserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  dialogModificaFolder() {
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 124,
        h: 124
      }
    ]
    const solicitareDetaliiProdus: SolicitareDetaliiProdus = {
      idProdus: this.folder.idProdus,
      formatIco: formatIcoContinut
    }
    this.arhivaService.DetaliiProdus(solicitareDetaliiProdus).subscribe((detaliiProdus: DetaliiProdus) => {
      console.log('detaliiProdus', detaliiProdus)

      const dlgConfig = new MatDialogConfig();
      dlgConfig.data = {
        detaliiProdus: detaliiProdus,
      }
      this.matDialog.open(DialogModificaFolderComponent, {
        data: dlgConfig.data,
        autoFocus: false,
        width: '700px',
      }).afterClosed().subscribe((text: string) => {
        if (text === 'modificat') {
          this.router.navigateByUrl('/');
        }
      })
    });

  }


}
