<br>

<div class="container" #scrollMe>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <a [routerLink]="['/administrare-utilizatori']">
        <button mat-stroked-button>
          Administrare Utilizatori
        </button>
      </a>
      <a [routerLink]="['/arhiva']">
        <button mat-stroked-button>
          Arhiva
        </button>
      </a>
      <a [routerLink]="['/panouri']">
        <button mat-stroked-button>
          Panouri
        </button>
      </a>
    </div>
    <br>

    <!-- bazaUserService.ExistaJwtValid$: {{bazaUserService.ExistaJwtValid$ | async}} -->

    <app-buton-autentificare></app-buton-autentificare>

  </div>

  <br>
  <mat-divider></mat-divider>

  <!-- breadCrumb -->
  <div fxLayout="row" fxLayoutAlign="start center" style="margin: 5px 0 0 0;">
    <ng-container *ngIf="segmentUrl.length > 0">
      <a 
        class="breadCrumb"
        [routerLink]="['/arhiva']"
      >
        <p>Arhiva</p>
      </a>
      <ng-container *ngIf="segmentUrl.length > 0">
        <p class="dash">&nbsp;/&nbsp;</p>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!!segmentUrl && segmentUrl.length >= 1">
      <a 
        class="breadCrumb"
        [routerLink]="['/arhiva', segmentUrl[0]]"
      >
        <p>{{segmentUrl[0]}}</p>
      </a>
      <ng-container *ngIf="segmentUrl.length === 2">
        <p class="dash">&nbsp;/&nbsp;</p>
      </ng-container>
    </ng-container>
    
    <ng-container *ngIf="!!segmentUrl && segmentUrl.length >= 2">
      <a 
        class="breadCrumb"
        [routerLink]="['/arhiva', segmentUrl[0], segmentUrl[1]]"
      >
        {{segmentUrl[1]}}
        <ng-container *ngIf="segmentUrl.length === 3">
          <p class="dash">&nbsp;/&nbsp;</p>
        </ng-container>
      </a>
    </ng-container>

    <ng-container *ngIf="!!segmentUrl && segmentUrl.length >= 3">
      <a 
        class="breadCrumb"
        [routerLink]="['/arhiva', segmentUrl[0], segmentUrl[1], segmentUrl[2]]"
      >
        {{segmentUrl[2]}}
        <ng-container *ngIf="segmentUrl.length === 4">
          <p class="dash">&nbsp;/&nbsp;</p>
        </ng-container>
      </a>
    </ng-container> 
  </div>

  <div fxLayout="row" fxLayoutAlign="end center"  fxLayoutGap="15px" style="margin-top: 10px">

    <button mat-stroked-button (click)="deschideDialog('folder')">
      Select Folder
    </button>

    <button mat-stroked-button (click)="deschideDialog('fisier')">
      Select fisier
    </button>
  </div>

  <button (click)="AdaugaPanouDemo()">Adauga panou demo</button>

  <br>

</div>