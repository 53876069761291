import { AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../backendapi/services';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor( 
    private bazaUserService: BazaUserService,
    private router: Router,
    private mesaj: AtentionareAcsService,
    private authService: AuthService,
    private mAuth: MsalService,
  ) { 
  }

  logout() {
    this.bazaUserService.Token = undefined;
    localStorage.clear();
  }

}

