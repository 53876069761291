/* tslint:disable */
import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';
import { HttpClientModule, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ArhivaService } from './services/arhiva.service';
import { AuthService } from './services/auth.service';
import { CfgPanouService } from './services/cfg-panou.service';
import { FisierService } from './services/fisier.service';
import { StreamService } from './services/stream.service';
import { UtilizatoriService } from './services/utilizatori.service';
import { Apis, ResolverUniversal } from './resolver-universal';
import { tap } from 'rxjs/operators';
import { BazaUserService } from '@acs/infrastructura-standard';

@Injectable()
export class H3 implements HttpInterceptor {

  constructor(private userService: BazaUserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const id_token = this.userService.Token;
    const cod_api = environment.CodApi_4WQagGn6VOjA;
    let request: Observable<HttpEvent<any>>;
    if (id_token) {
      let headers = req.headers.set('Authorization', 'Bearer ' + id_token);
      if(cod_api) {
        headers = headers.append('X-ACS-CODAPI', cod_api);
      }
      const cloned = req.clone({
        headers: headers
      });
      request = next.handle(cloned);
    } else {
      request = next.handle(req);
    }

    return request.pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          const header_cautat = 'x-token-nou';
          if (evt.headers.has(header_cautat)) {
            const token_nou = evt.headers.get(header_cautat);
            this.userService.Token = token_nou;;
          }
        }
      })
    );
  }
}

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    Apis,
    ResolverUniversal,
    ArhivaService,
    AuthService,
    CfgPanouService,
    FisierService,
    StreamService,
    UtilizatoriService,
	,{provide: HTTP_INTERCEPTORS, useClass: H3, multi: true}
  ],
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [ ]
    };
  }
}