<ng-container>
  <ng-container *ngIf="bazaUserService.ExistaJwtValid$ | async; else butonLogin">
    <button 
      mat-stroked-button 
      (click)="userService.logout(); router.navigate([currentLocation]);"
    >
      <p>Logout</p>
    </button>
  </ng-container>

  <ng-template #butonLogin>
    <button 
      mat-stroked-button
      (click)="setFocus(); $event.preventDefault()"
      [matMenuTriggerFor]="meniuAdauga"
      (menuClosed)="form.reset()"
    >
      <p>Login</p>
    </button>
  </ng-template>

</ng-container>

<mat-menu 
  backdropClass="customize"
  #meniuAdauga="matMenu" 
  xPosition="before" 
  (click)="$event.stopPropagation();"
>
  <div fxLayout="column" (click)="$event.stopPropagation()">

    <div fxLayout="row" fxLayoutAlign="center center" style="margin: 10px 0 15px 0;">
      <p>Autentificare</p>
    </div>
    <!-- router.navigate([currentLocation]); -->
    <form 
      [formGroup]="form" 
      (keydown.enter)="form.valid ? autentificare() : $event.stopPropagation()"
      (keydown.tab)="$event.stopPropagation()"
      (click)="$event.stopPropagation()" 
    >
      <div fxLayout="column">
        
        <mat-form-field  
          fxFlex.lt-sm 
          class="input"
        >
          <input 
            matInput
            #username 
            placeholder="Nume utilizator"
            [formControlName]="'usernameAuth'" 
            type="text"
            required
          >
          <mat-error>Introduceţi o valoare</mat-error>
        </mat-form-field>
  
        <mat-form-field  
          fxFlex.lt-sm 
          class="input"
          style="margin-top: 7px;"
        >
          <input 
            matInput
            #password 
            placeholder="Parola"
            [formControlName]="'passwordAuth'" 
            type="password"
            required
          >
          <mat-error>Introduceţi o valoare</mat-error>
        </mat-form-field>
        
      </div>
    </form>
    
    <button
      fxFlex
      mat-stroked-button
      (click)="autentificare()"
      style="margin: 12px 0 12px 0;"
      [disabled]="form.invalid"
    >
      <p>Autentificare</p>
    </button>

    <div fxLayout="row" style="margin-top: 5px;">
      <mat-divider style="width: 40%; margin-top: 7px;"></mat-divider>
      <span style="padding: 0 6px; font-size: 12px; color: rgb(167, 167, 167);">sau</span>
      <mat-divider style="width: 45%; margin-top: 7px;"></mat-divider>
    </div>

    <div style="margin: 7px 0">
      <app-necesita-ms-login></app-necesita-ms-login>
    </div>

  </div>
</mat-menu>