import { RefFisierInArhiva } from './../../../backendapi/models/ref-fisier-in-arhiva';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefFolderInArhiva, SolicitareArhiva, SolicitareIco, ViewArhiva } from 'src/app/backendapi/models';
import { ArhivaService } from 'src/app/backendapi/services';
import { AtentionareAcsService } from '@acs/infrastructura-standard';

export interface ElementSelectat {
  folderListat: string[],
  folderListatNume: string[],
  idProdus: number
}

@Component({
  selector: 'app-dialog-panou-select',
  templateUrl: './dialog-panou-select.component.html',
  styleUrls: ['./dialog-panou-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogPanouSelectComponent implements OnInit {

  viewArhiva: ViewArhiva;
  elementMouseHover: number;
  tipDeSelect: string;
  listaPanouri: string[];
  panouSelectat: string = 'panou1';
  fisiere: RefFisierInArhiva[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogPanouSelectComponent>,
    private formBuilder: FormBuilder,
    private arhivaService: ArhivaService,
    private mesaj: AtentionareAcsService,
  ) {
    this.viewArhiva = data.data as ViewArhiva;
    this.tipDeSelect = data.tipDeSelect;
    this.listaPanouri = data.listaPanouri;
    console.log('data', data)
  }

  ngOnInit(): void {
  }

  fetchData(folderListat?: string[]) {
    const pathFromRoot = folderListat ? folderListat : [];
    const formatIcoHeader: SolicitareIco[] = [
      {
        w: 128,
        h: 128
      },
      {
        w: 64,
        h: 64
      },
    ];
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 64,
        h: 64
      }
    ];
    const solicitareArhiva: SolicitareArhiva = {
      pathFromRoot,
      formatIcoHeader,
      formatIcoContinut
    };

    this.arhivaService.CitesteArhiva(solicitareArhiva).subscribe((viewArhiva: ViewArhiva) => {
      // se poate muta logica din modificaDirectorul() aici pentru a nu 
      // mai adauga/elimina elemtnul din breadCrumb daca nu exista folder in viewArhiva

      if (this.tipDeSelect === 'produs' && !!viewArhiva.foldere && viewArhiva.foldere.length === 0) {
        // nu se face navigarea 
        this.mesaj.Eroare("Produsul nu contine folder!");
        
        this.viewArhiva.folderListat.pop();
      } else {
        this.fisiere = viewArhiva.fisiere;
        this.viewArhiva = viewArhiva;
      }
    })
  }

  determinamDacaFolderulAreFisier(folderSite: string, folder: RefFolderInArhiva) {

    let pathFromRoot: string[] = [...this.viewArhiva.folderListat];
    pathFromRoot.push(folderSite);

    const formatIcoHeader: SolicitareIco[] = [
      {
        w: 128,
        h: 128
      },
      {
        w: 64,
        h: 64
      },
    ];
    const formatIcoContinut: SolicitareIco[] = [
      {
        w: 64,
        h: 64
      }
    ];
    const solicitareArhiva: SolicitareArhiva = {
      pathFromRoot,
      formatIcoHeader,
      formatIcoContinut
    };

    this.arhivaService.CitesteArhiva(solicitareArhiva).subscribe((viewArhiva: ViewArhiva) => {
      if (!!viewArhiva.fisiere && viewArhiva.fisiere.length > 0) {
        // exista fisier
        this.alegeFolder(folder);
      } else {
        this.mesaj.Succes("Produsul nu contine fisier!");
      }
    })
  }

  modificaDirectorul(folderSite: string) {
    let pathFromRoot: string[] = this.viewArhiva.folderListat;
    pathFromRoot.push(folderSite);
    this.fetchData(pathFromRoot)
  }

  navigare(pathFromRoot: string[]) {
    this.fetchData(pathFromRoot);
  }

  alegeFisier(fisier: RefFisierInArhiva) {
    this.dialogRef.close(fisier);
  }

  alegeFolder(folder: RefFolderInArhiva) {
    this.dialogRef.close(folder);
  }

  renunta() {
    this.dialogRef.close();
  }
}

