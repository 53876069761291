<div [ngStyle]="{'cursor': 'all-scroll'}">

  <div fxLayout="column" fxFlex style="margin: 13px 0">
    <div 
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      
      <ng-container *ngIf="modVizualizarePanou">
        <div fxLayout="column" style="min-width: 256px;">
          <!-- idProdusAfisat:{{idProdusAfisat}} -->

          <!-- daca este produs -->
          <ng-container *ngIf="!!idProdusAfisat; else idProdusAfisatNull">
            <p>Cel mai recent release pentru produs</p>
          </ng-container>

          <!-- daca este fisier -->
          <ng-template #idProdusAfisatNull>
            <p>Fisier ales in mod explicit</p> 
          </ng-template>

        </div>
      </ng-container>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <!-- <mat-icon 
          class="color-gri" 
          style="margin-right: 20px; font-size: 27px; cursor: help;"
          [matTooltip]="idProdusAfisat ? 
          'Cel mai recent Release (se actualizeaza automat daca se incarca un nou produs)'  : 
            'Fisier explicit'"
        >
          {{idProdusAfisat ? 'folder_open' : 'description'}}
        </mat-icon> -->
        
        <p [ngStyle]="{
          'margin-right': idProdusAfisat ? '60px' : '20px',
           'font-size': '15px'
          }" 
        >
          {{idProdusAfisat ? 'Produs' : 'Fisier explicit'}}
        </p>
        <!-- width: 90px; width: 60px; -->  
        <img
          [src]="!!fisier.ico[0] ? fisier.ico[0] : imagine"
          width= 90px 
          height= 60px
          alt="imagine-fisier"
          class="modifica_ico"
          fxFlex="60px"
        >
        <ng-container *ngIf="!!fisier.dimensiune;">
          <div fxLayout="column" fxFlex.gt-xs="180px" style="margin-left: 5px;">
            <p style="font-weight: 500;">{{fisier.numeFisier}}</p>
              <input
                style="
                  border: 1px solid gray;
                  border-radius: 6px;
                "
                matInput
                [ngModel]="overrideNumeFisier"
                (ngModelChange)="overrideNumeFisiers.emit($event)"
                #overrideNumeFisierNgModel="ngModel"
                type="text"
                placeholder="overrideNumeFisier"
              >
            <p style="font-size: 12px !important">{{fisier.versiune}}</p>
            <p style="font-size: 12px !important">{{fisier.data}}</p>
            <p fxHide.gt-xs style="font-size: 12px !important">{{fisier.dimensiune}}</p>
          </div>
        </ng-container>
      </div>
    
      <div fxFlex>
        <p class="comentariu">{{fisier.comentariu}}</p>
      </div>
  
      <ng-container *ngIf="fisier.beta">
        <mat-chip-list aria-label="Fish selection">
          <mat-chip color="secondary" selected>Beta</mat-chip>
        </mat-chip-list>
      </ng-container>
      
      <ng-container *ngIf="!!fisier.dimensiune">
        <p 
          fxFlex.gt-xs="100px"
          fxFlexOffset="18px"  
          fxHide.lt-xs
          fxHide.xs
        >{{fisier.dimensiune}}</p>
      </ng-container>
  
      <div fxLayoutAlign="end center">
        <button 
          class="menu-button" 
          mat-icon-button 
          [mat-menu-trigger-for]="menu"           
          [color]="color"
          (mouseover)="color = 'primary'"
          (mouseout)="color = 'secondary'"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
      
    </div>
    <p fxFlex class="comentariu-telefon">{{fisier.comentariu}}</p>

    <div 
      *ngIf="!fisier.dimensiune;"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <p class="textProdus">Informatiile produsului se vor actualiza dupa salvare.</p>
    </div>
  </div>
</div>

<mat-menu x-position="before" #menu="matMenu">
  <button mat-menu-item (click)="inlocuiesteElement.emit('fisier');">
    <p>
      <mat-icon>published_with_changes</mat-icon>
      Inlocuieste cu un fisier
    </p>
  </button>

  <button mat-menu-item (click)="inlocuiesteElement.emit('produs');">
    <p>
      <mat-icon>published_with_changes</mat-icon>
      Inlocuieste cu un produs
    </p>
  </button>

  <mat-divider></mat-divider>

  <button mat-menu-item (click)="stergeElement();">
    <p>
      <mat-icon>delete_outline</mat-icon>
      Sterge
    </p>
  </button>
</mat-menu>