import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RefFisierInArhiva } from 'src/app/backendapi/models';
import { DialogUniversalComponent } from 'src/app/utils/dialogs/dialog-universal/dialog-universal.component';

@Component({
  selector: 'app-panou-element',
  templateUrl: './panou-element.component.html',
  styleUrls: ['./panou-element.component.scss']
})
export class PanouElementComponent implements OnInit {

  @Input() fisier: RefFisierInArhiva = {};
  @Input() idProdusAfisat: number;
  @Input() imagine: string = '';
  @Input() segmentUrl: string[] = [];
  @Input() modVizualizarePanou: boolean = false;
  @Output() stergereElement = new EventEmitter<void>();
  @Output() inlocuiesteElement = new EventEmitter<string>();
  color="secondary"

  private _overrideNumeFisier: string;
  @Input('overrideNumeFisier')
  set overrideNumeFisier(value: string) {
    if (value !== this.overrideNumeFisier) {
      this._overrideNumeFisier = value;
      this.overrideNumeFisiers.emit(value);
    }
  }
  get overrideNumeFisier() {
    return this._overrideNumeFisier;
  }
  
  @Output('overrideNumeFisierChange')
  overrideNumeFisiers = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  stergeElement() {
    console.log('stergeElement', );
    // dialog confirmare
    const dlgConfig = new MatDialogConfig();
    dlgConfig.data = {
      header_text: "Confirmare stergere produs panou",
      text: "Sigur doriti sa stergeti produsul: ",
      nume: this.fisier.numeFisier + '?'
    };

    this.matDialog.open(DialogUniversalComponent, {
      data: dlgConfig,
      autoFocus: false,
    }).afterClosed().subscribe(rezultat => {
      if (!!rezultat) {
        this.stergereElement.emit();
      }
    })
  }


  test123() {
    const name = 123123
    return 'return value'
  }

}
