/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, catchError as __catchError, finalize as __finalize } from 'rxjs/operators';
import { ApiEndpointService, AtentionareAcsService, BazaUserService } from '@acs/infrastructura-standard';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { DetaliiProdus } from '../models/detalii-produs';
import { SolicitareAdaugaUtilizatorACL } from '../models/solicitare-adauga-utilizator-acl';
import { UtilizatorAcl } from '../models/utilizator-acl';
import { SolicitareArhiva } from '../models/solicitare-arhiva';
import { ViewArhiva } from '../models/view-arhiva';
import { SolicitareDetaliiProdus } from '../models/solicitare-detalii-produs';
import { SolicitareReordonare } from '../models/solicitare-reordonare';
@Injectable({
  providedIn: 'root',
})
class ArhivaService extends __BaseService {
  static readonly AdaugaProdusPath = '/api/Arhiva/AdaugaProdus';
  static readonly AdaugaUtilizatorLaAclPath = '/api/Arhiva/AdaugaUtilizatorLaAcl';
  static readonly CitesteArhivaPath = '/api/citestearhiva';
  static readonly DetaliiProdusPath = '/api/Arhiva/DetaliiProdus';
  static readonly ModificaProdusPath = '/api/Arhiva/ModificaProdus';
  static readonly PornesteSincronizareBDPath = '/api/Arhiva/SincronizeazaBD';
  static readonly ReordoneazaProdusePath = '/api/Arhiva/ReordoneazaProduse';
  static readonly SchimbaParinteProdusPath = '/api/Arhiva/SchimbaParinteProdus';
  static readonly StatusUltimaSincronizarePath = '/api/Arhiva/StatusUltimaSincronizare';
  static readonly StergeProdusPath = '/api/Arhiva/StergeProdus';

  constructor(
    config: __Configuration,
    http: HttpClient,
    user: BazaUserService,
    apiEndpoint: ApiEndpointService,
	at: AtentionareAcsService,
  private router: Router
  ) {
    super(config, http, user, apiEndpoint, at);
  }

  /**
   * @param AdaugaProdusBodyParam
   * @return Success
   */
  AdaugaProdusResponse(AdaugaProdusBodyParam: DetaliiProdus, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdaugaProdusBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Arhiva/AdaugaProdus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    const cod = 'AdaugaProdus' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<number>;
      })
    );
  }

  /**
   * @param AdaugaProdusBodyParam
   * @return Success
   */
  AdaugaProdus(AdaugaProdusBodyParam: DetaliiProdus, faraProgressBar?: boolean): __Observable<number> {
    return this.AdaugaProdusResponse(AdaugaProdusBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param AdaugaUtilizatorLaAclBodyParam
   * @return Success
   */
  AdaugaUtilizatorLaAclResponse(AdaugaUtilizatorLaAclBodyParam: SolicitareAdaugaUtilizatorACL, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<UtilizatorAcl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AdaugaUtilizatorLaAclBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Arhiva/AdaugaUtilizatorLaAcl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'AdaugaUtilizatorLaAcl' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UtilizatorAcl>;
      })
    );
  }

  /**
   * @param AdaugaUtilizatorLaAclBodyParam
   * @return Success
   */
  AdaugaUtilizatorLaAcl(AdaugaUtilizatorLaAclBodyParam: SolicitareAdaugaUtilizatorACL, faraProgressBar?: boolean): __Observable<UtilizatorAcl> {
    return this.AdaugaUtilizatorLaAclResponse(AdaugaUtilizatorLaAclBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as UtilizatorAcl)
    );
  }

  /**
   * @param CitesteArhivaBodyParam
   * @return Success
   */
  CitesteArhivaResponse(CitesteArhivaBodyParam: SolicitareArhiva, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<ViewArhiva>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = CitesteArhivaBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/citestearhiva`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'CitesteArhiva' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewArhiva>;
      })
    );
  }

  /**
   * @param CitesteArhivaBodyParam
   * @return Success
   */
  CitesteArhiva(CitesteArhivaBodyParam: SolicitareArhiva, faraProgressBar?: boolean): __Observable<ViewArhiva> {
    return this.CitesteArhivaResponse(CitesteArhivaBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as ViewArhiva)
    );
  }

  /**
   * @param DetaliiProdusBodyParam
   * @return Success
   */
  DetaliiProdusResponse(DetaliiProdusBodyParam: SolicitareDetaliiProdus, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<DetaliiProdus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = DetaliiProdusBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Arhiva/DetaliiProdus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'DetaliiProdus' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DetaliiProdus>;
      })
    );
  }

  /**
   * @param DetaliiProdusBodyParam
   * @return Success
   */
  DetaliiProdus(DetaliiProdusBodyParam: SolicitareDetaliiProdus, faraProgressBar?: boolean): __Observable<DetaliiProdus> {
    return this.DetaliiProdusResponse(DetaliiProdusBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as DetaliiProdus)
    );
  }

  /**
   * @param ModificaProdusBodyParam
   */
  ModificaProdusResponse(ModificaProdusBodyParam: DetaliiProdus, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ModificaProdusBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Arhiva/ModificaProdus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'ModificaProdus' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param ModificaProdusBodyParam
   */
  ModificaProdus(ModificaProdusBodyParam: DetaliiProdus, faraProgressBar?: boolean): __Observable<null> {
    return this.ModificaProdusResponse(ModificaProdusBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   */
  PornesteSincronizareBDResponse(faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Arhiva/SincronizeazaBD`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'PornesteSincronizareBD' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   */
  PornesteSincronizareBD(faraProgressBar?: boolean): __Observable<null> {
    return this.PornesteSincronizareBDResponse(faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ReordoneazaProduseBodyParam
   */
  ReordoneazaProduseResponse(ReordoneazaProduseBodyParam: SolicitareReordonare, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ReordoneazaProduseBodyParam;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Arhiva/ReordoneazaProduse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'ReordoneazaProduse' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param ReordoneazaProduseBodyParam
   */
  ReordoneazaProduse(ReordoneazaProduseBodyParam: SolicitareReordonare, faraProgressBar?: boolean): __Observable<null> {
    return this.ReordoneazaProduseResponse(ReordoneazaProduseBodyParam, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ArhivaService.SchimbaParinteProdusParams` containing the following parameters:
   *
   * - `idProdus`:
   *
   * - `idParinte`:
   *
   */
  SchimbaParinteProdusResponse(params: ArhivaService.SchimbaParinteProdusParams, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProdus != null) __params = __params.set('idProdus', params.idProdus.toString());
    if (params.idParinte != null) __params = __params.set('idParinte', params.idParinte.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Arhiva/SchimbaParinteProdus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'SchimbaParinteProdus' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `ArhivaService.SchimbaParinteProdusParams` containing the following parameters:
   *
   * - `idProdus`:
   *
   * - `idParinte`:
   *
   */
  SchimbaParinteProdus(params: ArhivaService.SchimbaParinteProdusParams, faraProgressBar?: boolean): __Observable<null> {
    return this.SchimbaParinteProdusResponse(params, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  StatusUltimaSincronizareResponse(faraProgressBar?: boolean): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Arhiva/StatusUltimaSincronizare`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    const cod = 'StatusUltimaSincronizare' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @return Success
   */
  StatusUltimaSincronizare(faraProgressBar?: boolean): __Observable<string> {
    return this.StatusUltimaSincronizareResponse(faraProgressBar).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param idProdus
   */
  StergeProdusResponse(idProdus?: number, faraProgressBar?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idProdus != null) __params = __params.set('idProdus', idProdus.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Arhiva/StergeProdus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    const cod = 'StergeProdus' + Math.floor(Math.random() * 999999) + '_' + Math.floor(Math.random() * 999999);

    if (faraProgressBar !== true) { this.atentionare.PornesteProgressBar(cod); }

    return this.http.request<any>(req)
    .pipe(
      __finalize(() => { if (faraProgressBar !== true) { this.atentionare.OpresteProgressBar(cod); }}),
      __catchError(err => {
        const mesaj = __BaseService.ExtrageMesajEroare(err, err.status === 401);
        if (err.status === 401) {
          this.userService.MergiLaLogin();
        } else if (err.status === 589) {
          // Am facut deja refresh? Verific daca codul din envirnoment este acelasi cu cel din local storage
          const item = localStorage.getItem('dataRefreshDeploy');
          const data_acum = new Date();
          // ISO este format-ul YYYY-MM-DDTHH:mm:ss.sssZ. Folosesc asta pentru ca javascript nu are implementat
          // un mecanism pentru stabilirea unui format manual
          const data_acum_as_string = data_acum.toISOString();
          if (item) {
            try {
              var data_refresh = new Date(item);
              data_refresh.setMinutes(data_refresh.getMinutes() + 2);
              if(data_refresh < data_acum) {
                localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
                this.userService.NavigheazaLaHomepage(true);
              }
            } catch {
              localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            }
          } else {
            localStorage.setItem('dataRefreshDeploy', data_acum_as_string);
            this.userService.NavigheazaLaHomepage(true);
          }
        } else if (err.status === 403) {
            this.atentionare.Eroare(mesaj);
        } else {
            this.atentionare.Eroare(mesaj);
        }
        throw mesaj;
      }),
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param idProdus
   */
  StergeProdus(idProdus?: number, faraProgressBar?: boolean): __Observable<null> {
    return this.StergeProdusResponse(idProdus, faraProgressBar).pipe(
      __map(_r => _r.body as null)
    );
  }

}

module ArhivaService {

  /**
   * Parameters for SchimbaParinteProdus
   */
  export interface SchimbaParinteProdusParams {
    idProdus?: number;
    idParinte?: number;
  }
}

export { ArhivaService }